// src/features/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    accountUuid: null,
    token: null,
    isLoggedIn: false,
    accountName: null,
  },
  reducers: {
    setUserAuthInfo: (state, action) => {
      // Destructure all needed fields from action.payload
      const { accountUuid, token, isLoggedIn, accountName } = action.payload;
      state.accountUuid = accountUuid;
      state.token = token;
      state.isLoggedIn = isLoggedIn;
      state.accountName = accountName;
    },
    clearUserAuthInfo: state => {
      state.accountUuid = null;
      state.token = null;
      state.isLoggedIn = false;
      state.accountName = null;
    },
  },
});

export const { setUserAuthInfo, clearUserAuthInfo } = userSlice.actions;

export const selectAuth = state => state.user; // Selector to access the auth state easily

export default userSlice.reducer;