import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const TotalToFix = ({ data }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!data) return;

        const width = 400;
        const marginTop = 0;  // Reduce margin at the top
        const marginRight = 10;
        const marginBottom = 30;  // Added space for the axis
        const marginLeft = 30;

        const totalHeight = 80;  // Adjust based on your visual needs
        const svg = d3.select(ref.current)
            .attr("width", width)
            .attr("height", totalHeight)
            .attr("viewBox", [0, 0, width, totalHeight])
            .attr("style", "max-width: 100%; height: auto; display: block;");

        // Clear the SVG to prevent redrawing issues
        svg.selectAll("*").remove();

        // Process the data for stacking
        const series = d3.stack()
            .keys(["Red", "Orange"])
            .value((d, key) => d[key])([data]);

        const x = d3.scaleLinear()
            .domain([0, d3.max(series, s => d3.max(s, d => d[1]))])
            .range([marginLeft, width - marginRight]);

        const y = d3.scaleBand()
            .domain(series.map(s => s.key))
            .range([totalHeight - marginBottom, marginTop])  // Reverse the range to move bars below axis
            .padding(0.2);

        const color = d3.scaleOrdinal()
            .domain(series.map(s => s.key))
            .range(["#E83636", "#DE7539"]);  // Colors for Red and Orange

        svg.append("g")
            .selectAll("g")
            .data(series)
            .join("g")
                .attr("fill", d => color(d.key))
            .selectAll("rect")
            .data(d => d)
            .join("rect")
                .attr("x", d => x(d[0]))
                .attr("y", totalHeight - marginBottom - y.bandwidth())  // Align the bars to the bottom axis
                .attr("height", y.bandwidth())
                .attr("width", d => x(d[1]) - x(d[0]))
            .append("title")
                .text(d => `${d.data.key}: ${d[1] - d[0]} hours`);

        // Append Axes
        svg.append("g")
            .attr("transform", `translate(0,${totalHeight - marginBottom})`)
            .call(d3.axisBottom(x).ticks(width / 80, "s"))
            .call(g => g.selectAll(".domain").remove());

    }, [data]);

    return (
        <svg ref={ref} />
    );
}

export default TotalToFix;