import React from 'react';
import { Panel } from 'primereact/panel';
import './ToggleablePanel.css'; // Import the custom CSS

const StyledPanel = ({ header, children, ...props }) => {
  const renderHeader = (options) => {
    return (
      <div className="custom-panel-header">
        {header}
      </div>
    );
  };

  return (
    <Panel header={renderHeader} toggleable {...props} className="custom-panel">
      {children}
    </Panel>
  );
};

export default StyledPanel;
