// useAxiosConfig.js
import { useContext, useEffect } from 'react';
import { ToastContext } from './ToastContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const useAxiosConfig = () => {
    const toast = useContext(ToastContext);
    const navigate = useNavigate();

    useEffect(() => {
        axios.defaults.withCredentials = true;

        function getCsrfToken() {
            return document.cookie.split('; ')
                .find(row => row.startsWith('csrftoken='))
                ?.split('=')[1];
        }

        axios.interceptors.request.use(function (config) {
            const csrfToken = getCsrfToken();
            if (csrfToken) {
                config.headers['X-CSRFToken'] = csrfToken;
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Session expired', detail: 'Redirecting to login', life: 3000 });

                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                }
                return Promise.reject(error);
            }
        );
    }, [toast, navigate]);
};

export default useAxiosConfig;
