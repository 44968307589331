import { useState, useEffect } from 'react';
import axios from 'axios';

const useRepositories = (groupAuditUuid) => {
    const [repositoriesData, setRepositoriesData] = useState({
        repositories: [],
        total_number_files: 0,
        total_lines_of_code: 0,
        number_of_repos: 0
    });
    const [isLoadingRepositories, setIsLoadingRepositories] = useState(false);
    const [error, setError] = useState(null);

    const fetchRepositories = async () => {
        setIsLoadingRepositories(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/audit_scoped_repos/?audit_uuid=${groupAuditUuid}`);
            const data = response.data.data;
            setRepositoriesData({
                repositories: data.repositories || [],
                total_number_files: data.numberFiles || 0,
                total_lines_of_code: data.linesOfCode || 0,
                number_of_repos: data.numberOfRepos || 0
            });
        } catch (error) {
            console.error(error);
            setError("Failed to fetch repositories.");
        } finally {
            setIsLoadingRepositories(false);
        }
    };

    useEffect(() => {
        fetchRepositories();
        const interval = setInterval(fetchRepositories, 30000);
        return () => clearInterval(interval);
    }, [groupAuditUuid]);

    return { repositoriesData, isLoadingRepositories, error, fetchRepositories };
};

export default useRepositories;