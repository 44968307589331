import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ForgotPassword.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputComplete, setInputComplete] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email === '' || regex.test(email);
    };

    const validateForm = () => {
        return email !== '' && validateEmail(email) && Object.keys(errors).length === 0;
    };

    useEffect(() => {
        setInputComplete(validateForm());
    }, [email, errors]);

    const handleInputChange = (value) => {
        setEmail(value);
        let newErrors = { ...errors };
        delete newErrors.email;
        delete newErrors.api;

        if (!validateEmail(value) && value !== '') {
            newErrors.email = 'Invalid email address';
        }

        setErrors(newErrors);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setIsLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/reset-password/`, {
                    email
                });

                if (response.data.status === 'success') {
                    // Handle successful password reset request
                    alert('Password reset instructions have been sent to your email.');
                    navigate('/login');
                } else {
                    setErrors({ api: response.data.message });
                }
            } catch (error) {
                console.error('Password reset error:', error);
                if (error.response) {
                    setErrors({ api: error.response.data.message || 'An unexpected error occurred' });
                } else if (error.request) {
                    setErrors({ api: 'No response from server. Please try again.' });
                } else {
                    setErrors({ api: 'An unexpected error occurred' });
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="forgot-password-container">
            <Card className="forgot-password-card">
                <div className="forgot-password-header">
                    <h1>CodeDD</h1>
                    <h4>Reset your password</h4>
                </div>
                <div className="p-fluid">
                    <div className="p-field">
                        <span className="p-float-label">
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => handleInputChange(e.target.value)}
                                className={errors.email ? 'p-invalid' : ''}
                            />
                            <label htmlFor="email">Email</label>
                        </span>
                        {errors.email && <small className="p-error">{errors.email}</small>}
                    </div>
                    <Button
                        label={isLoading ? "Sending..." : "Reset Password"}
                        className="reset-password-button"
                        onClick={handleSubmit}
                        disabled={isLoading || !inputComplete}
                    />
                    {errors.api && <small className="p-error">{errors.api}</small>}
                    <div className="login-link">
                        <p>Already have an account? <a href="/login">Login</a></p>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ForgotPassword;