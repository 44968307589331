import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuditStatus } from '../utils/Actions';
import { useParams } from 'react-router-dom';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import { Button } from 'primereact/button';
import { selectAuth } from '../features/user/userSlice';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import ImprovedEmailInput from './ui_elements/ImprovedEmailInput';

import './InviteAuditors.css';

const InviteAuditors = ({ closeModal }) => {
    const [emails, setEmails] = useState([]);
    const { csrfToken } = useCsrfToken();
    const { auditUuid } = useParams();
    const { accountUuid } = useSelector(selectAuth);
    const auditAccessDetails = useSelector(state => state.audits[auditUuid]);
    const [isPublic, setIsPublic] = useState(auditAccessDetails.isPublic);
    const [isLoading, setIsLoading] = useState(false);
    const [invitedAuditors, setInvitedAuditors] = useState([]);
    const [first, setFirst] = useState(0);
    const toast = useRef(null);
    const [disableButtons, setDisableButtons] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchInvitedAuditors();
    }, []);

    const fetchInvitedAuditors = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/get_invited_auditors/`, {
            params: { audit_uuid: auditUuid },
            headers: {
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true
        })
        .then(response => {
            setInvitedAuditors(response.data.invited_auditors);
        })
        .catch(error => {
            console.error('Error fetching invited auditors:', error);
            toast.current.show({ severity: 'error', summary: 'Fetch Failed', detail: 'Failed to fetch invited auditors.', life: 3000 });
        });
    };

    const handleAccessChange = (newIsPublic) => {
        if (newIsPublic === isPublic) return;
        
        setIsLoading(true);
        setDisableButtons(true);
        axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/set_private_or_public/`, {
            audit_uuid: auditUuid,
            account_uuid: accountUuid,
            audit_access_details: { ...auditAccessDetails, isPublic: newIsPublic }
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true
        })
        .then(response => {
            setIsPublic(newIsPublic);
            setIsLoading(false);
            setTimeout(() => setDisableButtons(false), 3000);
            toast.current.show({ severity: 'success', summary: 'Access Updated', detail: 'The access level has been updated.', life: 3000 });
            dispatch(checkAuditStatus(auditUuid));
        })
        .catch(error => {
            console.error('Error:', error);
            setIsLoading(false);
            setTimeout(() => setDisableButtons(false), 3000);
            toast.current.show({ severity: 'error', summary: 'Access Update Failed', detail: 'Failed to update the access level.', life: 3000 });
            dispatch(checkAuditStatus(auditUuid));
        });
    };

    const inviteAuditors = () => {
        setIsLoading(true);
        setDisableButtons(true);
        axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/invite_auditors/`, {
            audit_uuid: auditUuid,
            account_uuid: accountUuid,
            emails: emails
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true
        })
        .then(response => {
            setIsLoading(false);
            setTimeout(() => setDisableButtons(false), 3000);
            toast.current.show({ severity: 'success', summary: 'Invitations Sent', detail: 'Auditors have been invited successfully.', life: 3000 });
            setEmails([]);
            fetchInvitedAuditors();
        })
        .catch(error => {
            console.error('Error:', error);
            setIsLoading(false);
            setTimeout(() => setDisableButtons(false), 3000);
            toast.current.show({ severity: 'error', summary: 'Invitation Failed', detail: 'Failed to invite auditors.', life: 3000 });
        });
    };

    const statusTemplate = (rowData) => {
        return <span className={`invited-auditors-status ${rowData.status.toLowerCase().replace(' ', '-')}`}>{rowData.status}</span>;
    };

    const onPage = (event) => {
        setFirst(event.first);
    };

    return (
        <div className='manage-access'>
            <Toast ref={toast} />
            <h2>Invite auditors</h2>
            <h4>Here you can invite other auditors to the audit report or change the audit to public.</h4>
            <ImprovedEmailInput emails={emails} setEmails={setEmails} />
            <h4>You can invite up to 10 auditors at the same time.</h4>
            <Button 
                label="Invite Auditors" 
                icon="pi pi-check" 
                onClick={inviteAuditors} 
                disabled={isLoading || emails.length === 0} 
                style={{ width: '200px', marginBottom: '50px' }} 
            />
            
            {invitedAuditors.length > 0 && (
                <div className="invited-auditors-section">
                    <h2>Invited Auditors</h2>
                    <DataTable 
                        value={invitedAuditors} 
                        responsiveLayout="scroll"
                        paginator 
                        rows={10} 
                        first={first} 
                        onPage={onPage}
                        style={{ minWidth: '470px', width: '100%' , marginBottom: '50px' }}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        className="custom-invited-auditors-table"
                    >
                        <Column field="email" header="Email" />
                        <Column field="status" header="Status" body={statusTemplate} />
                    </DataTable>
                </div>
            )}

            <h2>Change visibility</h2>
            <h4>You can change the visibility of this audit to public or private.</h4>
            <div className='select-privacy-section'>
                <Button 
                    label="Make public"  
                    icon="pi pi-globe" 
                    onClick={() => handleAccessChange(true)}
                    disabled={isLoading || disableButtons || isPublic}
                    className={isPublic ? 'p-button-success' : 'p-button-outlined p-button-secondary'}
                />
                <Button 
                    label="Make private" 
                    icon="pi pi-key" 
                    onClick={() => handleAccessChange(false)}
                    disabled={isLoading || disableButtons || !isPublic}
                    className={!isPublic ? 'p-button-success' : 'p-button-outlined p-button-secondary'}
                />
                {isLoading && <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" />}
            </div>
        </div>
    );
};

export default InviteAuditors;