import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Empty.css';

export default function EmptyPlaceholder() {
    const navigate = useNavigate();
    const [daysLeft, setDaysLeft] = useState(7);

    useEffect(() => {
        const timer = setInterval(() => {
            setDaysLeft((prevDays) => {
                if (prevDays > 0) {
                    return prevDays - 1;
                } else {
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 86400000); // 24 hours in milliseconds

        return () => clearInterval(timer);
    }, []);

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <div className="start-container">
            <div className="content empty-content">
                <div className="card empty-card">
                    <h1 className="start-heading">Coming Soon!</h1>
                    <div className="empty-illustration">
                        <svg width="100%" height="100%" viewBox="0 0 200 200" preserveAspectRatio="xMidYMid meet" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="100" cy="100" r="90" stroke="#32AFC3" strokeWidth="10"/>
                            <path d="M100 50v60M70 110h60" stroke="#32AFC3" strokeWidth="10" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <h2 className="start-subheading">Exciting content is on its way!</h2>
                    <p className="empty-message">We're working hard to bring you something amazing. Check back in:</p>
                    <div className="countdown">
                        <span className="days">{daysLeft}</span>
                        <span className="days-label">days</span>
                    </div>
                    <div className="button-container">
                        <button onClick={handleGoHome} className="empty-button primary">Go Home</button>
                    </div>
                </div>
            </div>
        </div>
    );
}