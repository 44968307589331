import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Error.css';

export default function ErrorPage() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <div className="start-container">
            <div className="content error-content">
                <div className="card error-card">
                    <h1 className="start-heading">Oops! Something went wrong</h1>
                    <div className="error-illustration">
                        <svg width="100%" height="100%" viewBox="0 0 200 200" preserveAspectRatio="xMidYMid meet" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="100" cy="100" r="90" stroke="#E83636" strokeWidth="10"/>
                            <path d="M65 65L135 135M135 65L65 135" stroke="#E83636" strokeWidth="10" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <h2 className="start-subheading">Don't worry, it happens to the best of us!</h2>
                    <p className="error-message">The page you're looking for might have been moved, deleted, or is temporarily unavailable.</p>
                    <div className="button-container">
                        <button onClick={handleGoBack} className="error-button">Go Back</button>
                        <button onClick={handleGoHome} className="error-button primary">Go Home</button>
                    </div>
                </div>
            </div>
        </div>
    );
}