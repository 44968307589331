import { SET_AUDIT_STATUS, SET_AUDIT_ERROR } from './Actions';

const initialState = {
};

export default function auditReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUDIT_STATUS:
      console.log('Reducer - SET_AUDIT_STATUS:', action.payload);
      const { uuid, isPublic, auditStatus, hasAccess, isManager } = action.payload;
      return {
        ...state,
        [uuid]: { isPublic, auditStatus, hasAccess, isManager, error: null }
      };
    case SET_AUDIT_ERROR:
      const { uuid: errorUuid, error } = action.payload; // Destructure here as well
      return {
        ...state,
        [errorUuid]: { ...state[errorUuid], error }
      };
    default:
      return state;
  }
}
