// CsrfTokenContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const CsrfTokenContext = createContext();

export const CsrfTokenProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState('');
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/set_csrf_token/`, { withCredentials: true })
      .then(response => {
        setCsrfToken(response.data.csrfToken);
      })
      .catch(error => {
        console.error('Error setting CSRF token:', error);
      });
  }, []);

  // Check if CRSF token is "cleaned" after login or that it correctly expires. 
  return (
    <CsrfTokenContext.Provider value={{ csrfToken, setCsrfToken }}>
      {children}
    </CsrfTokenContext.Provider>
  );
};

export const useCsrfToken = () => useContext(CsrfTokenContext); 
