import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import auditReducer from './utils/Reducer';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

// Persist configuration for user data
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'] // specifies that only user state is persisted
};

// Combining user, audit, and auth reducers
const rootReducer = combineReducers({
  user: userReducer, // Existing user reducer
  audits: auditReducer, // Existing audit reducer
});

// Applying the persist configuration to the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configuring the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
      },
    }),
});

// Creating the persistor for the Redux store
export const persistor = persistStore(store);
