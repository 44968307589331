import React from 'react';
import SummaryScoreGraph from './SummaryScoreGraph'; 

import './ScoreGraph.css';

const ScoreGraph = ({ scoresData, auditDetails }) => {
  return (
    <div className="score-graph-container">
      {scoresData.map((data, index) => (
        <div className="score-row" key={index}>
          <div className="score-title">{data.title}</div>
          <SummaryScoreGraph
            className="score-slider-graph"
            scoreAttribute={data.scoreAttribute}
            name={data.title}
            isMainChapter={true}
            auditDetails={auditDetails}
          />
        </div>
      ))}
    </div>
  );
};

export default ScoreGraph;
