import React from 'react';
import { Divider } from 'primereact/divider';
import './ChapterDivider.css'; // Import the custom CSS

const ChapterDivider = ({ title, icon }) => {
    return (
      <Divider className="custom-divider" align="left">
        <div className="p-divider-content">
          <div className="inline-flex align-items-center">
            {icon && <i className={`${icon} custom-divider-icon`}></i>}
            <b className="custom-divider-title">{title}</b>
          </div>
        </div>
      </Divider>
    );
  };
  
  export default ChapterDivider;