
import React from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Link, useLocation } from 'react-router-dom';

import './TabMenu.css'; 

const TabMenuComponent = () => {
  const location = useLocation();
  const auditUuid = location.pathname.split('/')[1]; // Assumes the UUID is the second segment of the path

  const items = [
    { label: 'Summary', icon: 'pi pi-fw pi-home', url: `/${auditUuid}/audit_summary` },
    { label: 'Flags', icon: 'pi pi-fw pi-flag', url: `/${auditUuid}/flags` },
    { label: 'Dependencies', icon: 'pi pi-fw pi-pencil', url: `/${auditUuid}/dependencies` },
    { label: 'Files', icon: 'pi pi-fw pi-file', url: `/${auditUuid}/files` },
  ];

  return (
    <TabMenu model={items.map(item => ({
      ...item,
      template: (item, options) => (
        <Link to={item.url} className={`tabmenu-item-link ${location.pathname === item.url ? 'active-tab' : ''}`}>
          <i className={item.icon}></i>
          {item.label}
        </Link>
      )
    }))} activeIndex={items.findIndex(item => location.pathname === item.url)} className="custom-tabmenu" />
  );
};

export default TabMenuComponent;
