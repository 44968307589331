import { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserAuthInfo } from '../../features/user/userSlice';
import { useCsrfToken } from '../../utils/CsrfTokenContext';
import { Message } from 'primereact/message';

import axios from 'axios';
import './Login.css';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputComplete, setInputComplete] = useState(false);
    const [apiError, setApiError] = useState('');
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const loginButtonRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { csrfToken } = useCsrfToken();

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email === '' || regex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const validateForm = () => {
        return email !== '' && validateEmail(email) && validatePassword(password) && Object.keys(errors).length === 0;
    };    

    useEffect(() => {
        setInputComplete(validateForm());
    }, [email, password, errors]);

    const handleInputChange = (field, value) => {
        let newErrors = { ...errors };
        delete newErrors[field];
        delete newErrors.api;

        switch (field) {
            case 'email':
                setEmail(value);
                if (!validateEmail(value) && value !== '') {
                    newErrors.email = 'Invalid email address';
                }
                break;
            case 'password':
                setPassword(value);
                if (!validatePassword(value) && value !== '') {
                    newErrors.password = 'Password must be at least 8 characters long';
                }
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setIsLoading(true);
            setApiError(''); // Clear any previous API errors
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/login/`, {
                    email,
                    password
                }, {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRFToken': csrfToken,
                    },
                    withCredentials: true 
                });
    
                if (response.data.status === 'success') {
                    dispatch(setUserAuthInfo({ 
                        token: response.data.token, 
                        accountUuid: response.data.account_uuid, 
                        isLoggedIn: true, 
                        accountName: response.data.account_name 
                    }));
    
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('accountUuid', response.data.account_uuid);
                    localStorage.setItem('accountName', response.data.account_name);
    
                    const from = location.state?.from?.pathname || '/dashboard';
                    navigate(from, { replace: true });
                } else {
                    setApiError(response.data.message);
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    setApiError(error.response.data.message || 'An error occurred during login.');
                } else {
                    setApiError('An unexpected error occurred. Please try again.');
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputComplete && !isLoading) {
            handleSubmit();
        }
    };

    return (
        <div className="login-container">
            <Card className="login-card">
                <div className="login-header">
                    <h1>Log in to CodeDD</h1>
                </div>
                {apiError && (
                    <Message severity="error" text={apiError} style={{ marginBottom: '1.5rem', width: '100%' }} />
                )}
                <div className="p-fluid">
                    <div className="p-field">
                        <span className="p-float-label">
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => handleInputChange('email', e.target.value)}
                                onKeyDown={handleKeyDown}
                                className={errors.email ? 'p-invalid' : ''}
                                ref={emailRef}
                            />
                            <label htmlFor="email">Email</label>
                        </span>
                        {errors.email && <small className="p-error">{errors.email}</small>}
                    </div>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Password
                                id="password"
                                value={password}
                                onChange={(e) => handleInputChange('password', e.target.value)}
                                onKeyDown={handleKeyDown}
                                className={errors.password ? 'p-invalid' : ''}
                                feedback={false}
                                ref={passwordRef}
                            />
                            <label htmlFor="password">Password</label>
                        </span>
                        {errors.password && <small className="p-error">{errors.password}</small>}
                    </div>
                    <Button
                        label={
                            <span style={{ color: 'white', fontSize: '1rem' }}>
                              {isLoading ? "Logging in..." : "Log in"}
                            </span>
                        }
                        className="login-button"
                        onClick={handleSubmit}
                        disabled={isLoading || !inputComplete}
                        ref={loginButtonRef}
                    />
                    <div className="forgot-password">
                        <a href="/forgot-password">Did you forget your password?</a>
                    </div>
                    <p className="terms-text">
                        By clicking "Log in" you accept the <a href="/terms">CodeDD Terms of Use</a> and acknowledge the <a href="/privacy-statement">Privacy Statement</a> and <a href="/data-processing-addendum">Data Processing Addendum</a>.
                    </p>
                </div>
            </Card>
        </div>
    );
};

export default Login;