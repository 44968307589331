import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import { Toast } from 'primereact/toast';
import InviterView from './audit_invitation_components/InviterView';
import InviteeView from './audit_invitation_components/InviteeView';
import RepositoryCard from '../components/RepositoryCard';
import AuditInvitationSummaryTab from './audit_invitation_components/AuditInvitationSummaryTab';
import EnterRepoCredentials from '../components/EnterRepoCredentials';
import useRepositories from './hooks/UseRepositories';

import './AuditInvitation.css';

const AuditInvitation = () => {
    const { csrfToken } = useCsrfToken();
    const [gitUrl, setGitUrl] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckingUrl, setIsCheckingUrl] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState('');
    const [userRole, setUserRole] = useState(null);
    const [userName, setUserName] = useState('');
    const [auditName, setAuditName] = useState('');
    const [email, setInviteeEmail] = useState('');

    const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
    const user = JSON.parse(persistRoot?.user || '{}');
    const accountUuid = user.accountUuid;

    const location = useLocation();
    const groupAuditUuid = location.pathname.split('/')[1];

    const { repositoriesData, isLoadingRepositories, error: repositoriesError, fetchRepositories } = useRepositories(groupAuditUuid);

    const [submittedUrls, setSubmittedUrls] = useState([]);
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);

    const [auditStarted, setAuditStarted] = useState(false);
    const [auditFinished, setAuditFinished] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        const identifyUserRole = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/identify_audit_role/?audit_uuid=${groupAuditUuid}&account_uuid=${accountUuid}`);
                setUserRole(response.data.role);
                setUserName(response.data.inviterName);
                setAuditName(response.data.auditName);
                setInviteeEmail(response.data.email);
            } catch (error) {
                setErrorMessage("Error identifying user role.");
                setErrorType('roleIdentificationError');
                console.error(error);
            }
        };

        identifyUserRole();
    }, [groupAuditUuid, accountUuid, csrfToken]);

    const isValidGitUrl = (url) => {
        const pattern = /^(https?:\/\/)?(www\.)?(github\.com|gitlab\.com|bitbucket\.org|sourceforge\.net|code.google.com|codeplex\.com|launchpad\.net|savannah\.gnu\.org|freecode\.com|gitkraken\.com|beanstalkapp\.com|assembla\.com|phabricator\.com|gogs\.io|gitea\.com|gitbucket\.github\.io|codeberg\.org|azure\.com|dev\.azure\.com|bitbucket\.com|sr\.ht|dags\.hub\.com|chiselapp\.com|repo\.or\.cz|notabug\.org|kallithea-scm\.org|git\.savannah\.gnu\.org|repo\.openpandora\.org|git\.kernel\.org)\/[\w\-\.\/]+$/;
        return pattern.test(url);
    };

    const handleInputChange = (event) => {
        setGitUrl(event.target.value);
        setIsValidUrl(true);
    };

    const handleSubmit = async () => {
        if (auditStarted) return;
        
        setIsLoading(true);
        setErrorMessage('');
        setErrorType('');
        setIsCheckingUrl(true);
        setIsValidUrl(true);
    
        if (!isValidGitUrl(gitUrl)) {
            setErrorMessage("Invalid Git URL format.");
            setErrorType('invalidUrlFormat');
            setIsValidUrl(false);
            setIsCheckingUrl(false);
            setIsLoading(false);
            return;
        }
        
        try {
            const validationResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/django_codedd/validate_git_url`, 
                { git_repo_url: gitUrl, account_uuid: accountUuid }, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken,
                    },
                    withCredentials: true 
                }
            );
            
            setIsCheckingUrl(false);

            if (validationResponse.data.isValid) {
                const auditScopeResponse = await axios.post(
                    `${process.env.REACT_APP_API_URL}/django_codedd/audit_scope_selector/`,
                    { git_repo_url: gitUrl, account_uuid: accountUuid, g_audit_uuid: groupAuditUuid },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': csrfToken,
                        },
                        withCredentials: true
                    }
                );
    
                if (auditScopeResponse.data.success) {
                    setSubmittedUrls(prev => [...prev, gitUrl]);
                    setGitUrl('');
                    fetchRepositories();
                    showSuccess(gitUrl);
                } else {
                    handleValidationError(validationResponse.data.errorType);
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleValidationError = (errorType) => {
        switch (errorType) {
            case 'invalidUrl':
                setErrorMessage("This is not a valid URL.");
                setErrorType('invalidUrl');
                setIsValidUrl(false);
                break;
            case 'invalidGitUrl':
                setErrorMessage("This is not a valid git root URL to clone.");
                setErrorType('invalidGitUrl');
                setIsValidUrl(false);
                break;
            case 'accessError':
                setErrorMessage("The git URL is protected and could not be accessed.");
                setErrorType('accessError');
                setIsValidUrl(false);
                break;
            default:
                setErrorMessage("An unknown error occurred.");
                setErrorType('unknown');
                setIsValidUrl(false);
                break;
        }
    };

    const handleError = (error) => {
        setIsCheckingUrl(false);
        setIsLoading(false);
    
        if (error.response && error.response.data) {
            const { message, errorType } = error.response.data;
            
            if (message === "SSH access denied.") {
                setShowCredentialsModal(true);
            } else {
                setErrorMessage(message);
                setErrorType(errorType);
                setIsValidUrl(false);
            }
        } else {
            console.error('Error:', error);
            setErrorMessage('An error occurred while processing the URL.');
            setErrorType('unknown');
            setIsValidUrl(false);
        }
    };

    const showSuccess = (url) => {
        toast.current.show({severity: 'success', summary: 'URL Submitted', detail: url, life: 3000});
    };

    return (
        <div className="audit-invitation-container">
            <EnterRepoCredentials
                isVisible={showCredentialsModal}
                onHide={() => setShowCredentialsModal(false)}
                gitUrl={gitUrl}
                style={{ width: '900px' }}
                onAuthenticate={handleSubmit}
                isLoading={isLoading}
                accountUuid={accountUuid}
            />
            <Toast ref={toast} />
            <AuditInvitationSummaryTab
                key={JSON.stringify(repositoriesData)}
                visible={true} 
                onHide={() => {}}
                userRole={userRole}
                repositoriesData={repositoriesData}
                groupAuditUuid={groupAuditUuid}
                userName={userName}
                fetchRepositories={fetchRepositories}
                auditStarted={auditStarted}
                setAuditStarted={setAuditStarted}
                setAuditFinished={setAuditFinished}
                auditFinished={auditFinished}
            />
            <div className="audit-invitation-content">
                {userRole === 'inviter' && (
                    <InviterView 
                        auditName={auditName}
                        userRole={userRole}
                        userName={userName}
                        inviteeEmail={email}
                        auditFinished={auditFinished}
                    />
                )}
                {userRole === 'invitee' && (
                    <InviteeView
                        auditName={auditName}
                        gitUrl={gitUrl}
                        isCheckingUrl={isCheckingUrl}
                        isValidUrl={isValidUrl}
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        handleInputChange={handleInputChange}
                        handleSubmit={handleSubmit}
                        userName={userName}
                        showCredentialsModal={showCredentialsModal}
                        auditFinished={auditFinished}
                        auditStarted={auditStarted}
                    />
                )}
            </div>
            <div className="repo-list-container">
                <RepositoryCard 
                    fetchRepositories={fetchRepositories} 
                    className="repository-card"
                    style={{alignItems: "self-start"}}
                    repositories={repositoriesData.repositories}
                    groupAuditUuid={groupAuditUuid}
                    userRole={userRole}
                    auditStarted={auditStarted}
                    auditFinished={auditFinished}
                    isLoading={isLoadingRepositories}
                    error={repositoriesError}
                />
            </div>
        </div>
    );
};

export default AuditInvitation;