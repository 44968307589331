import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './AuditScopeSummaryTabMenu.css';
import { useCsrfToken } from '../utils/CsrfTokenContext';

const AuditScopeSummaryTabMenu = ({ visible, onHide, selectedNodes, groupAuditUuid }) => {
    const { csrfToken } = useCsrfToken();
    const [scopeSummary, setScopeSummary] = useState({
        numberOfFiles: 0,
        linesOfCode: 0
    });
    const [locBudget, setLocBudget] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [auditFinished, setAuditFinished] = useState(false);
    const [isAuditInitiating, setIsAuditInitiating] = useState(false);
    const [auditReady, setAuditReady] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const auditUuid = location.pathname.split('/')[1];
    const [accountUuid, setAccountUuid] = useState("");    

    useEffect(() => {
        const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
        const user = JSON.parse(persistRoot?.user || '{}');
        const fetchedAccountUuid = user.accountUuid || "";
    
        // Define fetchLocBudget inside useEffect to ensure it has access to the updated accountUuid
        const fetchLocBudget = async (accountUuid) => {
            if (!accountUuid) {
                console.error('Account UUID not found in local storage');
                return;
            }
        
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/api/get_loc_buget/?account_uuid=${accountUuid}`);
                setLocBudget(response.data.loc_budget);
            } catch (error) {
                console.error('Error fetching LOC budget:', error);
            }
        };
    
        if (fetchedAccountUuid) {
            setAccountUuid(fetchedAccountUuid); // Update state
            fetchLocBudget(fetchedAccountUuid); // Fetch LOC budget with the newly fetched accountUuid
        }

        fetchScopeSummary(); // Fetch the scope summary on initial render
    
    }, []);

    // Refactored fetchScopeSummary as a separate function
    const fetchScopeSummary = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/api/audit_scope_summary/${auditUuid}/`);
            setScopeSummary({
                numberOfFiles: response.data.number_files,
                linesOfCode: response.data.lines_of_code
            });
        } catch (error) {
            console.error('Error fetching scope summary:', error);
        }
    };

    const onConfirmClick = async () => {
        try {
            setIsLoading(true);
            const filePaths = selectedNodes.map(node => node.data.fullPath);
            const fileSelectionPayload = {
                files: filePaths,
                auditUuid: auditUuid,
            };
            await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/api/store_file_selection/`, fileSelectionPayload, {
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': csrfToken, // Use the stored token
                },
                withCredentials: true // Important for including the session cookie
              });

            await fetchScopeSummary(); // Refetch the scope summary after updating file selection
           
            setAuditReady(true); // Enable the audit start section
        } catch (error) {
            console.error('Error in process:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onStartAuditClick = async () => {

        setIsAuditInitiating(true); // Set audit initiation state

        if (!accountUuid) {
            console.error('Account UUID not found in local storage');
            setIsLoading(false);
            return;
        }

        setIsLoading(true); // Set loading state
        console.log('Starting audit with auditUuid:', auditUuid);
        axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/send_audit_confirmation_email/`, {
            auditUuid: auditUuid
        }, {
            headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken // Use the csrftoken from getCookie function
            },
            withCredentials: true
        })
        .then(response => {
            console.log('Email sent successfully');
        })
        .catch(error => {
            console.error('Error sending email:', error);
        });
    
        // Trigger the main process but don't wait for it to complete
        await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/api/trigger_main_process/`, 
            { auditUuid: auditUuid, accountUuid: accountUuid }, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken 
                },
                withCredentials: true
            })
            .then(response => {
                setAuditFinished(true);
            })
            .catch(error => {
                console.error('Error in triggering main process:', error);
            })
            .finally(() => {
                setIsLoading(false); // Reset loading state
                onHide(); // Hide the sidebar
            });

        axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/api/update_loc_budget/`, {
            accountUuid: accountUuid, // Ensure this is the actual accountUuid from your storage or state
            linesOfCodeUsed: scopeSummary.linesOfCode
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken 
            },
            withCredentials: true
        });
    
        // Immediately navigate to the audit summary view
        navigate(`/${auditUuid}/audit_summary`);
    };

    const goBackToOverview = () => {
        // Navigate back to the audit-invitation path
        navigate(`/${groupAuditUuid}/audit-invitation`);
    };
    

    const isConfirmDisabled = !selectedNodes || selectedNodes.length === 0 || auditFinished || isAuditInitiating || isLoading;
    const noFilesSelected = !selectedNodes || selectedNodes.length === 0;

    return (
        <Sidebar visible={true} modal={false} position="right" style={{ width: '300px' }} onHide={onHide}>
            <div className="audit-summary">
                <h2>Audit scope</h2>
                <div className="files-to-audit">
                    <span><strong>{(scopeSummary.numberOfFiles || 0).toLocaleString('de-DE')}</strong></span>
                    <label> files</label>
                </div>
                <div className="lines-of-code">
                    <span><strong>{(scopeSummary.linesOfCode || 0).toLocaleString('de-DE')}</strong></span>
                    <label> total lines of code</label>
                </div>
            </div>
            <Button label="Save File Selection" className="confirm-button" onClick={onConfirmClick} disabled={isConfirmDisabled} />
            {noFilesSelected && <div>No files selected.</div>}
            {isLoading && <ProgressBar mode="indeterminate" style={{ height: '6px', marginTop: '10px' }} />} 
    
            {/* Conditional rendering based on userRole and groupAuditUuid */}
            {!(groupAuditUuid) ? (
                <>
                    {/* New Section for starting the audit */}
                    {auditReady && (
                    <div className="audit-order">
                        <h2>Checkout</h2>

                        <div className="audit-info-row">
                            <span>Free LoC:</span>
                            <span className="audit-value"><b>{locBudget.toLocaleString('de-DE', {maximumFractionDigits: 0})}</b></span>
                        </div>

                        <div className="audit-info-row-small">
                            <span style={{fontStyle: "italic", fontSize: "14px" }}>After this audit:</span>
                            <span className="audit-value" style={{fontStyle: "italic", fontSize: "12px" }}>
                                <i style={{fontStyle: "italic", fontSize: "14px" }}>{Math.max(0, locBudget - scopeSummary.linesOfCode).toLocaleString('de-DE', {maximumFractionDigits: 0})}</i>
                            </span>
                        </div>

                        <div className="audit-info-row">
                            <span>From selection:</span>
                            <span className="audit-value"><b>{scopeSummary.linesOfCode.toLocaleString('de-DE', {maximumFractionDigits: 0})}</b></span>
                        </div>

                        <hr className="audit-divider" />

                        <div className="audit-info-row">
                            <span>Payable LoC:</span>
                            <span className="audit-value"><b>{Math.max(0, scopeSummary.linesOfCode - locBudget).toLocaleString('de-DE', {maximumFractionDigits: 0})}</b></span>
                        </div>

                        {/* Start Audit Button */}
                        <Button 
                        label={'Start Audit'}
                        className={`start-audit-button ${isLoading ? 'p-button-text p-button-plain' : ''}`} 
                        onClick={onStartAuditClick} 
                        disabled={isLoading || locBudget < scopeSummary.linesOfCode || auditFinished || isAuditInitiating}
                        />
                        {isLoading && <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" />}
                        {locBudget < scopeSummary.linesOfCode && <div className="audit-warning">Insufficient LOC budget to start the audit.</div>}
                    </div>
                )}
                </>
            ) : (
                <>
                    {/* "Back to Overview" button if groupAuditUuid is present */}
                    <Button 
                    label="Back to Overview"
                    icon="pi pi-angle-left"
                    className="back-button" 
                    style={{marginTop: '25px', width: '95%', border: '1px solid #8D8D8D'}} 
                    onClick={() => navigate(`/${groupAuditUuid}/audit-invitation`)} />
                </>
            )}
        </Sidebar>
    );
    
};

export default AuditScopeSummaryTabMenu;
