import axios from 'axios';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContext } from './utils/ToastContext';


// Ensure that cookies are sent with every request
axios.defaults.withCredentials = true;

// Function to get the CSRF token from cookies
function getCsrfToken() {
    return document.cookie.split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
}

// Request interceptor to add CSRF token to headers
axios.interceptors.request.use(function (config) {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Response interceptor to handle CSRF token errors
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) {
            // Handle CSRF token error, prompt user to refresh or re-authenticate
            const toast = useContext(ToastContext);
            const navigate = useNavigate();

            toast.current.show({ severity: 'error', summary: 'Session expired', detail: 'Redirecting to login', life: 3000 });

            setTimeout(() => {
                navigate('/login');
            }, 3000);
        }
        return Promise.reject(error);
    }
);

export default axios;
