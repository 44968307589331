import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const TotalToFixDomain = ({ data }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!data) return;

        const width = 400;
        const marginTop = 20;
        const marginRight = 10;
        const marginBottom = 30;
        const marginLeft = 120;  // Increased margin to prevent cropping of labels

        const domains = Object.keys(data);

        // Calculate total hours for each domain
        const domainTotals = Object.entries(data).map(([domain, value]) => ({
            domain,
            totalHours: value.Red + value.Orange,
            ...value
        }));

        // Sort domains by total hours in descending order and limit to top 5
        const topDomains = domainTotals
            .sort((a, b) => b.totalHours - a.totalHours)
            .slice(0, 5);

        const numberOfDomains = topDomains.length;
        const domainHeight = 40;  // Max height for each domain

        const totalHeight = marginTop + marginBottom + domainHeight * numberOfDomains;
        const svg = d3.select(ref.current)
            .attr("width", width)
            .attr("height", totalHeight)
            .attr("viewBox", [0, 0, width, totalHeight])
            .attr("style", "max-width: 100%; height: auto; display: block;");

        // Clear the SVG to prevent redrawing issues
        svg.selectAll("*").remove();

        // Process the data for stacking
        const series = d3.stack()
            .keys(["Red", "Orange"])
            .value((d, key) => d[key])(topDomains);

        const x = d3.scaleLinear()
            .domain([0, d3.max(series, s => d3.max(s, d => d[1]))])
            .range([marginLeft, width - marginRight]);

        const y = d3.scaleBand()
            .domain(topDomains.map(d => d.domain))
            .range([marginTop, totalHeight - marginBottom])
            .padding(0.2);

        const color = d3.scaleOrdinal()
            .domain(["Red", "Orange"])
            .range(["#E83636", "#DE7539"]);

        svg.append("g")
            .selectAll("g")
            .data(series)
            .join("g")
                .attr("fill", d => color(d.key))
            .selectAll("rect")
            .data(d => d)
            .join("rect")
                .attr("x", d => x(d[0]))
                .attr("y", d => y(d.data.domain))
                .attr("height", y.bandwidth())
                .attr("width", d => x(d[1]) - x(d[0]))
            .append("title")
                .text(d => `${d.data.domain}: ${d[1] - d[0]} hours`);

        // Append Axes
        svg.append("g")
            .attr("transform", `translate(0,${totalHeight - marginBottom})`)
            .call(d3.axisBottom(x).ticks(width / 80, "s"))
            .call(g => g.selectAll(".domain").remove());

        const yAxis = svg.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(d3.axisLeft(y))
            .call(g => g.selectAll(".domain").remove());

        yAxis.selectAll("text")
            .style("font-size", "12px");

    }, [data]);

    return (
        <svg ref={ref} />
    );
}

export default TotalToFixDomain;