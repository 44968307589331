import React, { useEffect } from 'react';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './RepositoryCard.css';

const RepositoryCard = ({ repositories, userRole, fetchRepositories, groupAuditUuid, auditStarted, auditFinished, isLoading, error }) => {
    const { csrfToken } = useCsrfToken();
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedAuditUuid, setSelectedAuditUuid] = useState('');
    const navigate = useNavigate();
   
    const [auditStatuses, setAuditStatuses] = useState({});

    // Define a mapping from audit status to progress percentage
    const auditStatusToProgress = {
        "File information imported": 0,
        "Audit started": 10,
        "Scores calculated": 50,
        "Contextualization completed": 80,
        "Audit completed": 100,
    };
    const [showProgressBar, setShowProgressBar] = useState({});

    useEffect(() => {
        // Initialize the audit statuses only once when the audit starts
        if (!auditStarted) {
            const initialStatuses = repositories.reduce((acc, repo) => {
                acc[repo.auditUuid] = repo.auditStatus || 'Pending'; // 'Pending' as a fallback status
                return acc;
            }, {});
            setAuditStatuses(initialStatuses);
        }
    }, [repositories, auditStarted]);

    useEffect(() => {

        if (auditStarted) {

        const fetchAllAuditStatuses = async () => {
            const statuses = {...auditStatuses}; // Start with the current statuses
            const progressBarDisplay = {};
    
            // Fetch status for each repository
            for (const repo of repositories) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/audit_status/`, {
                        params: { audit_uuid: repo.auditUuid },
                    });
                    statuses[repo.auditUuid] = response.data.audit_status;
                    // Determine if ProgressBar should be shown based on the fetched status
                    const showProgress = ["Audit started", "Scores calculated", "Contextualization completed", "Audit completed"].includes(response.data.audit_status);
                    progressBarDisplay[repo.auditUuid] = showProgress;

                } catch (error) {
                    console.error("Error getting audit status for UUID:", repo.auditUuid, error);
                    // Optionally handle error, e.g., by setting a status indicating failure
                }
            }
            setAuditStatuses(statuses); // Update state with all fetched statuses
            setShowProgressBar(progressBarDisplay); // Update state with ProgressBar display status
        };
    
        // Call once initially and then set an interval
        fetchAllAuditStatuses();
        let intervalId;
        if (!auditFinished) {
            intervalId = setInterval(fetchAllAuditStatuses, 10000); // Continue fetching periodically
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId); // Cleanup interval on component unmount
            }
        };
    }
    }, [auditStarted, auditFinished, repositories]);

    const getDomainLogo = (url) => {
        const domainLogos = {
            "github.com": '/images/GitHub.png',
            "gitlab.com": '/images/GitLab.png',
            "bitbucket.org": "/images/Bitbucket.png",
            "SourceForge.net": "/images/SourceForge.png",
            "code.google.com": "/images/GoogleCode.png",
            "codeplex.com": "/images/CodePlex.png",
            "launchpad.net": "/images/Launchpad.png",
            "savannah.gnu.org": "/images/Savannah.png",
            "freecode.com": "/images/Freecode.png",
            "gitkraken.com": "/images/GitKraken.png",
            "beanstalkapp.com": "/images/Beanstalk.png",
            "assembla.com": "/images/Assembla.png",
            "phabricator.com": "/images/Phabricator.png",
            "gogs.io": "/images/Gogs.png",
            "gitea.com": "/images/Gitea.png",
            "gitbucket.com": "/images/GitBucket.png",
            "codeberg.org": "/images/Codeberg.png",
            // ... other domains if necessary
        };
        const domain = new URL(url).hostname;
        return domainLogos[domain] || '/images/default-logo.png'; // Path to a default logo
    };

    // Function to handle button click
    const handleButtonClick = (repo) => {
        const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
        const auditStatus = auditStatuses[repo.auditUuid];
        const isTargetStatus = targetStatuses.includes(auditStatus);
        const path = auditFinished ? `/${repo.auditUuid}/audit-summary` : (isTargetStatus ? `/${repo.auditUuid}/audit-scope-selection` : `/${repo.auditUuid}/audit_summary`);
    
        navigate(path, { state: { userRole, groupAuditUuid } });
    };

    const calculateProgress = (auditUuid) => {
        const status = auditStatuses[auditUuid]; // Fetch status from the centralized state
        if (status === "Audit started" && !showProgressBar[auditUuid]) {
            // Initially set progress to 0% when status changes to "Audit started"
            return 0;
        }
        return auditStatusToProgress[status] || 0;
    };

    if (isLoading) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ProgressSpinner style={{marginTop: '3rem', with: '50px', height: '50px'}}/></div>;
    }

    if (error) {
        return <div style={{marginLeft: '50px'}}>Error loading repositories: {error}</div>;
    }

    const renderRepositories = () => {
        // Sorting repositories based on the `ai_synthesis` date in descending order
        const sortedRepositories = repositories.sort((a, b) => new Date(b.aiSynthesis) - new Date(a.aiSynthesis));

    
        return sortedRepositories.map((repo, index) => {
            const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
            const isTargetStatus = targetStatuses.includes(repo.auditStatus);
            const auditStatus = auditStatuses[repo.auditUuid] || 'Fetching status...';
            const cleanedRepoName = repo.repoName.replace(/-|_/g, " ");
            const buttonText = isTargetStatus ? "Adapt Scope" : "See Results";
            const progressValue = calculateProgress(repo.auditUuid);
            const shouldShowProgressBar = showProgressBar[repo.auditUuid];
        
            return (
                <div key={index} className={`repo-container ${index !== sortedRepositories.length - 1 ? 'repo-border' : ''}`}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                <img src={getDomainLogo(repo.url)} alt="Repo Logo" className="repo-logo" />
                                <div className="repo-info">
                                    <h2>{cleanedRepoName}</h2>
                                    <a href={repo.url} target="_blank" rel="noopener noreferrer" style={{ color: '#8D8D8D' }}>{repo.url}</a>
                                    <p style={{color: "#8D8D8D", fontSize: "16px"}}>{`${repo.numberFiles ? repo.numberFiles.toLocaleString() : '0'} files with ${repo.linesOfCode ? repo.linesOfCode.toLocaleString() : '0'} lines of code`}</p>
                                    <p style={{color: "#2CB392", fontSize: "14px"}}>Status: {auditStatus}</p>
                                </div>
                            </div>
                            <div className="repo-actions">
                                <Button label={buttonText} className="p-button-text" onClick={() => handleButtonClick(repo)} />
                                {isTargetStatus && (
                                    <Button icon="pi pi-trash" style={{border: "1px solid #E83636", width: "50px"}} className="p-button-danger p-ml-2" onClick={() => showDeleteDialog(repo.auditUuid)} />
                                )}
                            </div>
                        </div>
                        {shouldShowProgressBar && (
                            <ProgressBar className={`repo-card-progress-bar ${progressValue === 100 ? 'complete' : ''}`} style={{height: '10px'}} value={progressValue} displayValueTemplate={(value) => `${value}% Complete`} ></ProgressBar>
                        )}
                    </div>
                </div>
            );
        });
    };
    
    const deleteRepo = async () => {
        setDeleting(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/delete_repo_from_scope/`, { audit_uuid: selectedAuditUuid },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken, // Use the stored token
                },
                withCredentials: true // Important for including the session cookie
            });
            // Call fetchRepositories from the parent component to refresh the list
            fetchRepositories();
        } catch (error) {
            console.error("Error deleting repository:", error);
            // Handle error

        } finally {
            setDeleting(false);
            setDeleteDialogVisible(false); // Close the dialog
        }
    };

    const showDeleteDialog = (auditUuid) => {
        console.log("Deleting repository with UUID:", auditUuid);
        setSelectedAuditUuid(auditUuid);
        setDeleteDialogVisible(true);
    };

    const deleteConfirmationDialog = (
        <Dialog className="delete-repo-from-list" header="Confirm Delete" visible={deleteDialogVisible} style={{ minWidth: '350px', width:'30%' }} modal onHide={() => setDeleteDialogVisible(false)} footer={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Button label="Cancel" onClick={() => setDeleteDialogVisible(false)} className="p-button-text" disabled={deleting} style={{ marginLeft: '20px'}} />
                <Button label="Delete" onClick={deleteRepo} className="delete-audit-button" disabled={deleting}/>
            </div>
        }>
            {deleting ? <ProgressSpinner /> : <p style={{paddingLeft: '20px', paddingTop: '10px', paddingBottom: '20px'}}>Are you sure you want to delete this repository from auditing?</p>}
        </Dialog>
    );

    return (
        <Card className="card-container">
            <h2 className="audit-list-title">Audit List</h2>
            <p className="repositories-count"><strong style={{color: "#8D8D8D"}}>{repositories.length}</strong> repositories added for auditing</p>
            {repositories.length === 0 && (
                <>
                    <p className="audit-list-empty">Audit list is empty.</p>
                    <p className="audit-list-action-required">
                        {userRole === 'inviter' ? "The repository owner needs to add repositories for auditing." : "You need to add repositories for auditing."}
                    </p>
                </>
            )}
            {renderRepositories()}
            {deleteConfirmationDialog}
        </Card>
    );
};

export default RepositoryCard;
