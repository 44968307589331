import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import InviteAuditors from './InviteAuditors';
import { useAuditAccess } from '../utils/AuditAccessContext';
import { clearUserAuthInfo } from '../features/user/userSlice.js';
import { useCsrfToken } from '../utils/CsrfTokenContext';

import './GlobalSidebar.css';

const GlobalSidebar = ({ onLockToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuditView = /\/[a-f0-9-]+\/(audit_summary|flags|dependencies|files)/.test(location.pathname);

  const [expanded, setExpanded] = useState(false);
  const [locked, setLocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { hasAccess, isManager, auditUuid } = useAuditAccess();
  const dispatch = useDispatch();

  const { csrfToken } = useCsrfToken();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLocked(true);
    }, 5000);
    return () => clearTimeout(timer);
  });

  const toggleSidebar = () => {
    if (!locked) {
      setExpanded(prevExpanded => !prevExpanded);
    }
  };

  const lockSidebar = () => {
    const newLockedState = !locked;
    setLocked(newLockedState);
    if (onLockToggle) {
      onLockToggle(newLockedState);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/logout/`, {}, {
        headers: {
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true
      });

      console.log('Logout response:', response.data);

      if (response.data.status === 'success') {
        localStorage.removeItem('persist:root');
        localStorage.removeItem('token');
        localStorage.removeItem('accountUuid');
        localStorage.removeItem('accountName');
        dispatch(clearUserAuthInfo());
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        navigate('/');
      } else {
        console.error('Logout failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error during logout:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  };

  const SidebarButton = ({ icon, label, onClick, className }) => (
    <button className={`sidebar-button ${className}`} onClick={onClick}>
      <i className={`pi ${icon}`}></i>
      <span className="button-label">{label}</span>
    </button>
  );

  return (
    <div 
      className={`global-sidebar ${expanded ? 'expanded' : ''}`}
      onMouseEnter={toggleSidebar}
      onMouseLeave={toggleSidebar}
    >
      <div className="logo-container-sidebar">
        <Link to="/dashboard">
          <img src="/logo192.png" alt="Logo" className="sidebar-logo" />
        </Link>
      </div>

      <SidebarButton
        icon="pi-home"
        label="Dashboard"
        onClick={() => navigate('/dashboard')}
        className="dashboard-button"
      />

      {isManager && isAuditView && auditUuid && (
        <SidebarButton
          icon="pi-users"
          label="Manage Access"
          onClick={openModal}
          className="manage-access-button"
        />
      )}

      <div className="bottom-section">
        <SidebarButton
          icon="pi-user"
          label="Account"
          onClick={() => navigate('/account')}
          className="account-button"
        />
        <SidebarButton
          icon="pi-sign-out"
          label="Logout"
          onClick={handleLogout}
          className="logout-button"
        />
      </div>

      <Button
        icon={locked ? 'pi pi-lock' : 'pi pi-lock-open'}
        className="lock-button"
        onClick={lockSidebar}
      />

      <Dialog 
        className="manage-access-modal" 
        visible={showModal} 
        onHide={closeModal} 
        header="Manage Audit Access" 
        draggable={false}
        style={{ minWidth: '350px' }}
      >
        <InviteAuditors closeModal={closeModal} />
      </Dialog>
    </div>
  );
};

export default GlobalSidebar;