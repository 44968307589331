import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AuditScopeSummaryTabMenu from './AuditScopeSummaryTabMenu';
import { useCsrfToken } from '../utils/CsrfTokenContext';

import { useParams, useLocation } from 'react-router-dom';
import './AuditScopeSelection.css';

function AuditScopeSelection() {
    const { csrfToken } = useCsrfToken();
     const [nodes, setNodes] = useState([]); // Stores the tree nodes
     const [selectedNodeKeys, setSelectedNodeKeys] = useState({}); // Tracks the selected nodes
     const [selectAll, setSelectAll] = useState(false); // Tracks if all nodes are selected
     const [originalData, setOriginalData] = useState([]); // Stores the original unfiltered tree data
 
     // Various other state declarations for UI controls and filters
     const [globalFilter, setGlobalFilter] = useState(null);
     const [filterMode, setFilterMode] = useState('contains');
     const [selectedTypes, setSelectedTypes] = useState([]);
     console.log('Show SelectedTypes', selectedTypes);
     const [typeOptions, setTypeOptions] = useState([]);
     console.log('Show TypeOptions',  typeOptions);
     const [expandedRows, setExpandedRows] = useState({});
     const [showSidebar, setShowSidebar] = useState(false);
     const [selectedNodes, setSelectedNodes] = useState([]);
     const [editorContent, setEditorContent] = useState('');
     const toast = useRef(null);

 
     // Extract parameters from URL and state
     const params = useParams();
     const location = useLocation();
     const auditUuid = new URLSearchParams(location.search).get('auditUuid') || params.auditUuid;
     const groupAuditUuid = location.state?.groupAuditUuid;


    useEffect(() => {
        const fetchAuditScope = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/audit_scope_selector/${auditUuid}/`);
                if (response.data && response.data.tree_structure) {
                    const data = response.data.tree_structure;
                    // Use the formatAndSortData function to format and sort the data
                    const formattedData = formatAndSortData(data);
    
                    // Store the original data after processing
                    setOriginalData(formattedData);
    
                    // Initialize selectedNodeKeys based on is_selected property
                    const initialSelectedNodeKeys = initializeSelectedNodeKeys(formattedData);
                    setSelectedNodeKeys(initialSelectedNodeKeys);
    
                    // Extract unique types for the MultiSelect options
                    const uniqueTypes = Array.from(new Set(data.map(node => node.data.type)));
                    setTypeOptions(uniqueTypes.map(type => ({ label: type, value: type })));
                } else {
                    console.error('No tree structure data found in response');
                }
            } catch (error) {
                console.error('Error fetching audit scope:', error);
            }
        };
    
        if (auditUuid) {
            fetchAuditScope();
        }
    }, [auditUuid]);

    useEffect(() => {
        // Fetch existing comments for the audit
        axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/api/get_file_selection_comments/${auditUuid}/`)
            .then(response => {
                if (response.data && response.data.comments.length > 0) {
                    setEditorContent(response.data.comments[0]);
                } else {
                    setEditorContent('');
                }
            })
            .catch(error => {
                console.error('Error fetching file comments:', error);
            });
    }, [auditUuid]);

    // Function to handle the submission of editor content
    const handleSubmitComment = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/api/post_file_selection_comments/`, {
            audit_uuid: auditUuid, // Ensure auditUuid is defined in your component
            comment: editorContent
        },
        {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken, // Use the stored token
            },
            withCredentials: true // Important for including the session cookie
          })
        .then(response => {
            console.log('Comment submitted:', response.data);
            showSuccess();
        })
        .catch(error => {
            console.error('Error submitting comment:', error);
            showError();
        });
    };

    // Function to display success toast
    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Comment submitted successfully', life: 3000 });
    };

    // Function to display error toast
    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error submitting comment', life: 3000 });
    };

    // Initialize selectedNodeKeys based on is_selected property from data
    const initializeSelectedNodeKeys = (nodes) => {
        let newSelectedNodeKeys = {};
        nodes.forEach((node) => {
            newSelectedNodeKeys[node.key] = { checked: node.data.is_selected, partialChecked: false };
    
            if (node.children) {
                const childSelectedNodeKeys = initializeSelectedNodeKeys(node.children);
                Object.assign(newSelectedNodeKeys, childSelectedNodeKeys);
            }
        });
        return newSelectedNodeKeys;
    };

    const gatherSelectedNodes = (nodes, selectedKeys) => {
        let selected = [];
        nodes.forEach(node => {
            if (selectedKeys[node.key]?.checked) {
                selected.push(node);
            }
            if (node.children) {
                selected = [...selected, ...gatherSelectedNodes(node.children, selectedKeys)];
            }
        });
        return selected;
    };


    const wasNodeInitiallySelected = (nodes, key) => {
        for (let node of nodes) {
            if (node.key === key) {
                return node.data.is_selected;
            }
            if (node.children && wasNodeInitiallySelected(node.children, key)) {
                return true;
            }
        }
        return false;
    };  

   
    // Function to find a node by its key
    const findNodeByKey = (nodes, key) => {
        for (let node of nodes) {
            if (node.key === key) {
                return node;
            }
            if (node.children) {
                const found = findNodeByKey(node.children, key);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };
        

    // Function to format and sort data
    const formatAndSortData = (data) => {
        const formattedData = data.map(node => ({
            key: node.key,
            data: {
                name: node.data.name,
                size: node.data.size,
                type: node.data.type,
                fullPath: node.data.fullPath,
                is_selected: node.data.is_selected
            },
            children: node.children
        }));
        sortNodes(formattedData);
        return formattedData;
    };

    // Function to sort nodes so that folders are at the top
    const sortNodes = (nodes) => {
        nodes.sort((a, b) => {
            if (a.data.type === 'Folder' && b.data.type !== 'Folder') return -1;
            if (a.data.type !== 'Folder' && b.data.type === 'Folder') return 1;
            return a.data.name.localeCompare(b.data.name);
        });
        nodes.forEach((node) => {
            if (node.children) {
                sortNodes(node.children);
            }
        });
    };

    // Filter nodes by type when selectedTypes changes
    useEffect(() => {
        const filterByType = (nodeList) => {
            return nodeList.map(node => {
                if (selectedTypes.length === 0 || selectedTypes.includes(node.data.type)) {
                    return {
                        ...node,
                        children: node.children ? filterByType(node.children) : []
                    };
                }
                return null;
            }).filter(node => node);
        };

        // Re-apply the filter every time selectedTypes change
        if (selectedTypes.length > 0) {
            // Apply the filter to a copy of the original data
            const filteredNodes = filterByType([...originalData]);
            setNodes(filteredNodes);
        } else {
            // Reset to original data when no types are selected
            setNodes(originalData);
        }
    }, [selectedTypes, originalData]);

    // Update selectedNodes based on the selectedNodeKeys state
    useEffect(() => {
        const selectedNodesData = gatherSelectedNodes(nodes, selectedNodeKeys);
        setSelectedNodes(selectedNodesData);
    }, [selectedNodeKeys, nodes]);
    
    // Handle changes in node selection
    const onSelectionChange = (e) => {
        // Update the selection state based on the checkbox event
        setSelectedNodeKeys({ ...e.value });
    };
       
    // Handle select/deselect all functionality
    const onSelectAllChange = (e) => {
        const newSelectedKeys = {};
    
        const updateSelectionRecursively = (nodeList) => {
            nodeList.forEach((node) => {
                // If it's a file and was initially selected, update its state
                if (node.data.type !== 'Folder' && wasNodeInitiallySelected(originalData, node.key)) {
                    newSelectedKeys[node.key] = { checked: e.checked, partialChecked: false };
                }
    
                // If it has children, recursively update them
                if (node.children) {
                    updateSelectionRecursively(node.children);
                }
            });
        };
    
        updateSelectionRecursively(nodes); // Use nodes instead of originalData to ensure all nodes are updated
        setSelectedNodeKeys(newSelectedKeys);
        setSelectAll(e.checked);
    };

    // Automatically update selectAll state based on individual selections
    useEffect(() => {
        const allSelected = Object.keys(selectedNodeKeys).length > 0 
                            && Object.values(selectedNodeKeys).every(value => value.checked);
        setSelectAll(allSelected);
    }, [selectedNodeKeys]);
    
    // Manage individual node expansion
    const onNodeToggle = (e) => {
        setExpandedRows(e.value);
    };

    const tableheader = (
        <div className="table-header">
            <div className="select-container">
                <Checkbox onChange={onSelectAllChange} checked={selectAll} />
                <label>{selectAll ? 'Deselect All' : 'Select All'}</label>
            </div>
            <span className="p-input-icon-right">
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const renderEditorHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" title="Bold" aria-label="Bold"></button>
                <button className="ql-italic" title="Italic" aria-label="Italic"></button>
                <button className="ql-underline" title="Underline" aria-label="Underline"></button>
                <button className="ql-strike" title="Strike" aria-label="Strike"></button>
                <button className="ql-header" value="1" title="Header 1" aria-label="Header 1"></button>
                <button className="ql-header" value="2" title="Header 2" aria-label="Header 2"></button>
                <button className="ql-list" value="bullet" title="Bullet List" aria-label="Bullet List"></button>
                <button className="ql-clean" title="Remove Formatting" aria-label="Clean"></button>
            </span>
        );
    };
    
    const editorHeader = renderEditorHeader();
    
    return (
        <div className="audit-scope-selection">
            <div className="header-text">
                <h2>Select files you want to include in your due diligence.</h2>
                <p className="sub-text">
                    CodeDD automatically will only select source-code files to process. 
                </p>
                <p className="sub-text">
                    If you want to include or exclude other files, please select them manually.
                </p>
            </div>

            <AuditScopeSummaryTabMenu 
                visible={showSidebar} 
                onHide={() => setShowSidebar(false)} 
                selectedNodes={selectedNodes}
                groupAuditUuid={groupAuditUuid}
            />
            <TreeTable value={nodes} globalFilter={globalFilter} header={tableheader} 
                filterMode={filterMode}
                selectionKeys={selectedNodeKeys} onSelectionChange={onSelectionChange}
                expandedRows={expandedRows} onNodeToggle={onNodeToggle}
                tableStyle={{ minWidth: '50rem' }}>
                <Column headerStyle={{ width: '3em' }} body={(node) => {
                    if (node.data.type === 'Folder') {
                        return null;
                    }

                    const isInitiallySelected = wasNodeInitiallySelected(originalData, node.key);

                    return (
                        <Checkbox 
                            checked={selectedNodeKeys[node.key]?.checked} 
                            disabled={!isInitiallySelected}
                            onChange={(e) => {
                                const newSelectedKeys = { ...selectedNodeKeys };
                                newSelectedKeys[node.key] = { ...newSelectedKeys[node.key], checked: e.checked };
                                setSelectedNodeKeys(newSelectedKeys);
                            }} 
                        />
                    );
                }} />
                <Column field="name" header="Name" expander filter filterPlaceholder="Filter by name" className="name-column" />
                <Column field="size" header="Lines of Code" body={(node) => (
                    // Show a blank cell for folders
                    node.data.type === 'Folder' ? null : node.data.size
                )} />
                <Column field="type" header="Type" />
            </TreeTable>
            <div className="editor-container">
                <h3>You can comment on your file selection here:</h3>
                <Editor style={{height: '250px'}} headerTemplate={editorHeader} value={editorContent} onTextChange={(e) => setEditorContent(e.htmlValue)} />
                <Button className="save_button" label="Save" icon="pi pi-check" onClick={handleSubmitComment} />
                <Toast ref={toast} />
            </div>
        </div>
    );
}

export default AuditScopeSelection;