import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import hero_1 from './images/frontpage/hero_1.png';
import hero_2 from './images/frontpage/hero_2.png';
import intro_image_1 from './images/frontpage/hero_1.jpg';
import intro_image_2 from './images/frontpage/hero_1.jpg';
import './Start.css';

const HeroCard = ({ slide }) => (
  <div className="hero-card">
    <img src={slide.image} alt={slide.title} className="hero-card-image" />
    <h2 style={{marginBottom: '24px'}}>{slide.title}</h2>
    <div className="hero-card-link">
      <span>{slide.buttonText}</span>
      <i className="pi pi-angle-right"></i>
    </div>
  </div>
);

const IntroBlock = () => (
  <div className="intro-block">
    <div className="intro-section intro-section-1">
      <div className="intro-text-column">
        <h2>From Code to Confidence: Transparent Tech Investments</h2>
        <p>Review entire code stacks from software builders at top-level to file-level within minutes</p>
        <button className="intro-cta-button">Discover the platform</button>
      </div>
      <div className="intro-image-column">
        <img src={intro_image_1} alt="Software investment visualization" />
      </div>
    </div>
    <div className="intro-section intro-section-2">
      <div className="intro-image-column">
        <img src={intro_image_2} alt="IP protection visualization" />
      </div>
      <div className="intro-text-column">
        <h2>Transparent Insights, Protected Intellectual Property</h2>
        <p>Built to protect the core of software - the code - without limiting actionable insights into its quality</p>
        <button className="intro-cta-button">Read about security</button>
      </div>
    </div>
  </div>
);

export default function Start() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const scrollContainerRef = useRef(null);
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const heroContent = {
    title: "AI-powered Software Due Diligence",
    description: "CodeDD is the easiest, most comprehensive and safest code auditing platform",
    buttonText: "Start free trial"
  };

  const slides = [
    {
      title: "Automate your technical Due Diligence workflow",
      buttonText: "Discover CodeDD",
      image: hero_1,
      url: "/platform"
    },
    {
      title: "Build for tech-leading investors and innovators",
      buttonText: "Why CodeDD?",
      image: hero_2,
      url: "/why-codedd"
    }
    // Add more slides as needed
  ];

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      const newScrollPercentage = (scrollLeft / (scrollWidth - clientWidth)) * 100;
      setScrollPercentage(newScrollPercentage);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const distance = (x - startX) * 2; // Adjust multiplier for faster/slower scrolling
    scrollContainerRef.current.scrollLeft = scrollLeft - distance;
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - scrollContainerRef.current.offsetLeft;
    const distance = (x - startX) * 2; // Adjust multiplier for faster/slower scrolling
    scrollContainerRef.current.scrollLeft = scrollLeft - distance;
  };

  const scrollTo = (direction) => {
    if (scrollContainerRef.current) {
      const { clientWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollBy({ left: direction * clientWidth, behavior: 'smooth' });
    }
  };

  const handleStartFreeTrial = () => {
    navigate('/register');
  };

  const handleCardClick = (url) => {
    if (!isDragging) {
      navigate(url);
    }
  };

  return (
    <div className="hero-main-content">
      <div className="hero-section">
        <div 
          className="hero-content" 
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleMouseUp}
          onTouchMove={handleTouchMove}
        >
          <div className="hero-title-container">
            <h1 className="hero-title" style={{marginBottom: '24px'}}>{heroContent.title}</h1>
            <p className="hero-description" style={{marginBottom: '24px'}}>{heroContent.description}</p>
            <button className="hero-cta-button" onClick={handleStartFreeTrial}>{heroContent.buttonText}</button>
          </div>
          <div className="hero-cards-container">
            {slides.map((slide, index) => (
              <div key={index} onClick={() => handleCardClick(slide.url)}>
                <HeroCard slide={slide} />
              </div>
            ))}
          </div>
        </div>
        <div className="slider-container">
          <button onClick={() => scrollTo(-1)} className="slider-arrow">&#8592;</button>
          <div className="slider-bar">
            <div 
              className="slider-progress" 
              style={{width: `${scrollPercentage}%`}}
            ></div>
          </div>
          <button onClick={() => scrollTo(1)} className="slider-arrow">&#8594;</button>
        </div>
      </div>
      <IntroBlock />
    </div>
  );
}