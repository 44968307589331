import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import './EnterRepoCredentials.css';

import githubLogo from '../assets/images/GitHub.png';
import gitlabLogo from '../assets/images/GitLab.png';
import azureLogo from '../assets/images/Azure.png';
import bitbucketLogo from '../assets/images/Bitbucket.png';
import sourceforgeLogo from '../assets/images/SourceForge.png';

const getHostingServiceInfo = (url) => {
// Extracted for reusability and clarity
const commonInstructions = [
    'The above authentication method gives you the control to remove access at any point in time.',
    'You do not need to share any sensitive authentication credentials.'
];

let steps = [];
    if (!url) {
        return { name: '', logo: '', steps: [], commonInstructions };
    }


if (url.includes('github.com')) {
    steps = [
        { text: 'Log in to your ', highlight: 'GitHub account', postText: '.' },
        { text: 'Go to your personal account ', highlight: 'settings', postText: '.' },
        { text: 'Navigate to the menu ', highlight: 'SSH and GPG keys', postText: '.' },
        { text: 'Click on ', highlight: 'New SSH key', postText: '.' },
        { text: 'Paste the copied ', highlight: 'public SSH key ', postText: 'and save.' }
    ];
    return { name: 'GitHub', logo: githubLogo, steps, commonInstructions };
} else if (url.includes('gitlab.com')) {
    const steps = [
        { text: 'Log in to your ', highlight: 'GitLab account', postText: '.' },
        { text: 'Go to your personal account ', highlight: 'preferences', postText: '.' },
        { text: 'Navigate to the menu ', highlight: 'SSH Keys', postText: '.' },
        { text: 'Add a new key and copy / paste ', highlight: 'this public SSH key', postText: '.' },
        { text: 'Save by clicking ', highlight: 'Add key', postText: '.' },
    ];
    return { name: 'GitLab', logo: gitlabLogo, steps, commonInstructions };
} else if (url.includes('dev.azure.com')) {
    const steps = [
        { text: 'Log in to your ', highlight: 'Azure DevOps account', postText: '.' },
        { text: 'Go to your ', highlight: 'user settings', postText: '.' },
        { text: 'Navigate to the menu ', highlight: 'SSH public keys', postText: '.' },
        { text: 'Add a new key and paste the ', highlight: 'this public SSH key', postText: '.' },
        { text: 'Confirm by clicking ', highlight: 'Add', postText: '.' },
    ];
    return { name: 'Azure DevOps', logo: azureLogo, steps, commonInstructions };
}    else if (url.includes('bitbucket.org')) {
        steps = [
            { text: 'Log in to your ', highlight: 'Bitbucket account', postText: '.' },
            { text: 'Click your avatar in the bottom left and go to ', highlight: 'Personal settings', postText: '.' },
            { text: 'Under Access Management, navigate to ', highlight: 'SSH keys', postText: '.' },
            { text: 'Click on ', highlight: 'Add key', postText: '.' },
            { text: 'In the Label field, enter a descriptive label and paste the copied public SSH key into the ', highlight: 'Key field ', postText: '.' }
    ];
    return { name: 'Bitbucket', logo: bitbucketLogo, steps, commonInstructions };
} else if (url.includes('sourceforge.net')) {
    const steps = [
        { text: 'Log in to your ', highlight: 'SourceForge account', postText: '.' },
        { text: 'Navigate to your account settings and select the ', highlight: 'SSH Keys', postText: ' section.' },
        { text: 'Paste your public SSH key into the provided text area and ', highlight: 'submit it', postText: '.' }
    ];
    return { name: 'SourceForge', logo: sourceforgeLogo, steps, commonInstructions };
    } else {
    // A generic catch-all for other services or unrecognized URLs
    steps = [
    { text: 'Log in to your account at the Git hosting service.', highlight: '', postText: '' },
    { text: 'Find the section for SSH key management in your account settings.', highlight: '', postText: '' },
    { text: 'Add a new SSH key to your account.', highlight: '', postText: '' },
    { text: 'Copy and paste the public SSH key provided by CodeDD.', highlight: '', postText: '' }
    ];
    return { name: 'Other', logo: '', steps, commonInstructions };
    }
};

const EnterRepoCredentials = ({ isVisible, onHide, onAuthenticate, gitUrl, isLoading, validationError, accountUuid }) => {
    const hostingService = getHostingServiceInfo(gitUrl);
    const [copySuccess, setCopySuccess] = useState('');
    const [publicKey, setPublicKey] = useState('');
  
    useEffect(() => {
      const fetchPublicKey = async () => {
        if (!accountUuid) {
          console.error('accountUuid is undefined');
          setPublicKey('Error: Unable to fetch public key due to missing account information.');
          return;
        }
  
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/get_ssh_public_key/`, {
            params: { accountUuid }
          });
          setPublicKey(response.data.public_key);
        } catch (error) {
          console.error('Error fetching public key:', error);
          setPublicKey('Error fetching public key. Please try again.');
        }
      };
  
      if (isVisible) {
        fetchPublicKey();
      }
    }, [isVisible, accountUuid]);

    const instructionSteps = hostingService.steps.map((step, index) => (
        <li key={index}>
            <strong>Step {index + 1}:</strong> {step.text}
            <span className="highlight">{step.highlight}</span>
            {step.postText}
        </li>
    ));

    const PanelHeader = ({ hostingService }) => (
        <div className="title-container">
            <img src={hostingService.logo} alt={`${hostingService.name} Logo`} className="header-logo" />
            <div className="header-credentials-text">
                Audit a <strong>private</strong> repository on <span className="highlight">{hostingService.name}</span>
            </div>
        </div>
    );
    

    const copyToClipboard = () => {
        navigator.clipboard.writeText(publicKey).then(() => {
            setCopySuccess('Copied!');
        }, () => {
            setCopySuccess('Failed to copy!');
        });
    };

    return (
        <Dialog visible={isVisible} onHide={onHide} className="user-dashboard-modal">
            <div className="content-wrapper">
                    <PanelHeader hostingService={hostingService} />
                    <div className='instructions-key-container' >
                        <div className="ssh-instructions">
                                <p style={{marginBottom: '10px'}}> Follow these instructions to give CodeDD access to your {hostingService.name} repository:</p>
                            <ul>
                                {instructionSteps}
                            </ul>
                            <div className="confirmation">
                            <p style={{marginTop: '15px', marginBottom:'10px' }}> Why authenticating with public SSH key?</p>
                            <p><i className="pi pi-check" style={{color: "#2CB392", marginLeft: '5px'}}></i> You do not need to share any sensitive authentication credentials.</p>
                            <p><i className="pi pi-check" style={{color: "#2CB392", marginLeft: '5px'}}></i> It gives you the control to remove access at any point in time.</p>
                            </div>
                        </div>
                        <div className="vertical-separator"></div>
                        <div className="public-ssh-container">

                            <p style={{fontWeight: 'bold', marginBottom:'10px' }}>CodeDD Public SSH Key</p>
                            <p style={{marginBottom:'10px' }}>Copy the following public key and paste it in your <span className="highlight">{hostingService.name}</span> account settings.</p>
                            <pre className="ssh-key-text">{publicKey}</pre>
                            <Tooltip target=".copy-btn" />
                            <div className="copy-button-container">
                                <Tooltip target=".copy-btn" />
                                <Button icon="pi pi-copy" label="Copy to clipboard" className="copy-btn" onClick={copyToClipboard} tooltip={copySuccess ? copySuccess : 'Copy to clipboard'} tooltipOptions={{ position: 'bottom' }} />
                            </div>
                        </div>
                    </div>
                <div className="authenticate-footer">
                    <Button className="validate-access-button" label="Validate access & continue" onClick={onAuthenticate} disabled={isLoading} />
                </div>
                {validationError && <div className="error-message">{validationError}</div>}
            </div>
        </Dialog>
    );
};

export default EnterRepoCredentials;