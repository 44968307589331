import React, { useState, useRef } from 'react';
import { Chips } from 'primereact/chips';
import { Toast } from 'primereact/toast';

import './ImprovedEmailInput.css';

const ImprovedEmailInput = ({ emails, setEmails }) => {
  const toast = useRef(null);

  const validateEmail = (email) => {
    // This regex pattern checks for a basic email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const newEmails = e.value.filter((email, index) => {
      if (index >= 10) {
        toast.current.show({
          severity: 'warn',
          summary: 'Maximum Limit Reached',
          detail: 'You can only add up to 10 email addresses.',
          life: 3000
        });
        return false;
      }
      if (!validateEmail(email)) {
        toast.current.show({
          severity: 'error',
          summary: 'Invalid Email',
          detail: `"${email}" is not a valid email address.`,
          life: 3000
        });
        return false;
      }
      return true;
    });

    setEmails(newEmails);
  };

  return (
    <div className="email-input-container">
      <Toast ref={toast} />
      <Chips
        style={{ minWidth: '470px'}}
        value={emails}
        onChange={handleChange}
        separator=","
        placeholder={emails.length < 10 ? "Enter an email address and press enter" : "Maximum of 10 emails reached"}
        className="improved-email-chip email-specific-input"
        allowDuplicate={false}
        addOnBlur={true}
      />
    </div>
  );
};

export default ImprovedEmailInput;