import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="logo-container">
                    <Link to="/" className="logo-link">
                        <span className="logo-text">CodeDD</span>
                    </Link>
                </div>
                <div className="links-container">
                    <a href="/terms" className="footer-link">Terms of use</a>
                    <a href="/data-processing-addendum" className="footer-link">Source Code & Data management</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;