import React, { useState, useRef } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import axios from 'axios'; 
import './InviteToAudit.css';
import { useCsrfToken } from '../utils/CsrfTokenContext';

const InviteToAudit = ({ accountUuid }) => {
    const [auditName, setAuditName] = useState('');
    const [invitationEmail, setInvitationEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const navigate = useNavigate();
    const isButtonDisabled = auditName === '' || invitationEmail === '' || !emailValid;
    const [isSending, setIsSending] = useState(false);
    const toastRef = useRef(null);
    const { csrfToken } = useCsrfToken();

    const validateEmail = (email) => {
        // Simple regex for basic email validation
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        if (email.length <= 80) {
            setInvitationEmail(email);
            setEmailValid(validateEmail(email) || email === ''); // Valid or empty input is considered valid
        }
    };

    const handleAuditNameChange = (e) => {
        const name = e.target.value;
        if (name.length <= 80) {
            setAuditName(name);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isButtonDisabled) {
            sendAuditInvitation();
        }
    };

    const sendAuditInvitation = async () => {
        if (!validateEmail(invitationEmail)) {
            setEmailValid(false);
            return;
        }
    
        setIsSending(true); // Start sending, show spinner
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/send_audit_invitation/`, 
                { name: auditName, email: invitationEmail, account_uuid: accountUuid }, 
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRFToken': csrfToken, // Use the stored token
                    },
                    withCredentials: true // Important for including the session cookie
                  });
            // Assume the response includes the groupAuditUuid which is needed for navigation
            const { 
                audit_uuid
            } = response.data;
            toastRef.current.show({ severity: 'success', summary: 'Invitation Sent', detail: 'The audit invitation has been sent successfully.', life: 3000 });
            navigate(`/${audit_uuid}/audit-invitation`);
        } catch (error) {
            console.error('Error sending audit invitation:', error);
            toastRef.current.show({ severity: 'error', summary: 'Sending Failed', detail: 'Failed to send the audit invitation. Please try again.', life: 3000 });
        } finally {
            setIsSending(false); // Finished sending, hide spinner
        }
    };

    return (
        <div className="invite-to-audit-container">
            <Toast ref={toastRef} />
            <Card className='invitation-card'>
                <div className="invite-content">
                    <h2 className="invite-title">Invite repository owner for auditing</h2>
                    <p className="invite-text">Name your audit</p>
                    <InputText className="invitation-input-field" style={{width: "455px"}}  value={auditName} onChange={handleAuditNameChange} placeholder="e.g. SpaceX" maxLength={80} />
                    <p className="invite-text">Enter email from repository owner</p>
                    <InputText className={`invitation-input-field ${!emailValid ? 'p-invalid' : ''}`} style={{width: "455px"}} value={invitationEmail} onChange={handleEmailChange} onKeyPress={handleKeyPress} placeholder="e.g. john@smith.com" maxLength={80} />
                    <Button className={`send-invitation-button ${isButtonDisabled || isSending ? 'disabled' : ''}`} label="Send audit invitation" onClick={sendAuditInvitation} disabled={isButtonDisabled || isSending} />
                    {isSending && <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="8" />}
                </div>
            </Card>
        </div>
    );
};

export default InviteToAudit;
