import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { DataScroller } from 'primereact/datascroller';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector } from 'react-redux';
import { selectAuth } from '../features/user/userSlice';
import EnterRepoCredentials from '../components/EnterRepoCredentials';
import InviteToAudit from '../components/InviteToAudit';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import { utcToZonedTime } from 'date-fns-tz';
import { format as formatDate, differenceInMinutes, differenceInHours, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears } from 'date-fns';

import './UserDashboard.css';

function UserDashboard() {
    const { csrfToken } = useCsrfToken();
    const [gitUrl, setGitUrl] = useState('');
    const [audits, setAudits] = useState([]);
    const [groupAudits, setGroupAudits] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const limit = 10; // Number of audits to list per page
    const [offset, setOffset] = useState(0);
    const [hasLoadedMore, setHasLoadedMore] = useState(false);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [isLoading, setIsLoading] = useState(false);
    const [isCheckingUrl, setIsCheckingUrl] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState('');
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [shouldConnectWebSocket, setShouldConnectWebSocket] = useState(false);

    const { accountUuid } = useSelector(selectAuth);
    
    const navigate = useNavigate();

    const [auditUuid, setAuditUuid] = useState(''); 
    
    const [auditStarted, setAuditStarted] = useState(false);

    const fetchAuditDetails = async (auditUuid) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/audit_details/${auditUuid}/`);
            return response.data.file_count;
        } catch (error) {
            console.error('Error fetching audit details:', error);
            return 0;
        }
    };

    const fetchAudits = useCallback(async (loadMore = false) => {
        const newOffset = loadMore ? offset + limit : 0;
        setIsLoading(true);
        try {
            if (!accountUuid) {
                console.error('No account UUID found, redirecting to login.');
                navigate('/');
                return;
            }
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/user_audits/`, {
                params: {
                    offset: newOffset,
                    limit: limit,
                    account_uuid: accountUuid
                }
            });
            const fetchedAudits = response.data.audits_data || [];
            const fetchedGroupAudits = response.data.group_audits_data || [];

            const auditsWithDetails = await Promise.all(fetchedAudits.map(async audit => {
                const file_count = await fetchAuditDetails(audit.audit_uuid);
                return { ...audit, file_count };
            }));

            setAudits(prev => loadMore ? [...prev, ...auditsWithDetails] : auditsWithDetails);
            setGroupAudits(prev => loadMore ? [...prev, ...fetchedGroupAudits] : fetchedGroupAudits);
            setTotalItems(response.data.total_audits);
            setOffset(newOffset);

            if (loadMore) {
                setHasLoadedMore(true);
            }

        } catch (error) {
            console.error('Error fetching audits:', error);
        }
        setIsLoading(false);
    }, [accountUuid, navigate, offset, audits.length, groupAudits.length]);

    useEffect(() => {
        fetchAudits();
    }, [fetchAudits]);

    useEffect(() => {
        if (shouldConnectWebSocket) {
            const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/ws/django_codedd/audit/${accountUuid}/`);
            console.log("Connecting to WebSocket at:", ws.url);
    
            ws.onopen = () => {
                console.log('WebSocket connected');
            };
    
            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('Data from WebSocket:', data);
                if (data.message.status === 'File information imported') {
                    fetchAudits();
                }
            };
    
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
    
            ws.onclose = () => {
                console.log('WebSocket connection closed');
            };
    
            return () => {
                ws.close(); // Close WebSocket when component unmounts or when useEffect re-runs
            };
        }
    }, [shouldConnectWebSocket, accountUuid]); // Dependency array ensures that the effect runs only when it should

    const handleInputChange = (event) => {
        setGitUrl(event.target.value);
        setIsValidUrl(true); // Reset validation state on input change
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setErrorMessage('');
        setIsCheckingUrl(true);
        setAuditStarted(true); 
        setErrorType(''); // Reset error type

    try {
        const validationResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/django_codedd/validate_git_url`, { git_repo_url: gitUrl, account_uuid: accountUuid }, 
            {
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
              },
              withCredentials: true 
            }
          );
        
        setIsCheckingUrl(false);
      
        if (validationResponse.data.isValid) {

            setShouldConnectWebSocket(true); // Trigger WebSocket connection
            // Call API to trigger audit_scope_selector. We need to respond as soon as the audit session is craeted!
            const auditScopeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/audit_scope_selector/`,{ 
                git_repo_url: gitUrl,
                account_uuid: accountUuid // Pass account_uuid here
                },
                {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken, // Use the stored token
                },
                withCredentials: true // Important for including the session cookie
                });

            if (auditScopeResponse.data.success) {
                setAuditUuid(auditScopeResponse.data.audit_uuid);
                const auditUuid = auditScopeResponse.data.audit_uuid;
                setAuditStarted(true);
                navigate(`/${auditUuid}/audit-scope-selection`, { state: { git_repo_url: gitUrl } });
                    
            } else {
                switch (validationResponse.data.errorType) {
                    case 'invalidUrl':
                        setErrorMessage("This is not a valid URL.");
                        setErrorType('invalidUrl');
                        setIsValidUrl(false);
                        break;
                    case 'invalidGitUrl':
                        setErrorMessage("This is not a valid git root URL to clone.");
                        setErrorType('invalidGitUrl');
                        setIsValidUrl(false);
                        break;
                    case 'accessError':
                        setErrorMessage("The git URL is protected and could not be accessed.");
                        setErrorType('accessError');
                        setIsValidUrl(false);
                        break;
                    default:
                        setErrorMessage("An unknown error occurred.");
                        setErrorType('unknown');
                        setIsValidUrl(false);
                        break;
                }
            }
        }
    } catch (error) {
        setShouldConnectWebSocket(false); // Close Websocket connection on error
        setIsCheckingUrl(false); // Ensure this is also set to false here to stop the spinner
        setIsLoading(false); // Reset loading state
    
        if (error.response && error.response.data) {
            // Detailed error handling based on the response from the server
            const { message, errorType } = error.response.data;
            
            if (error.response.data.message === "SSH access denied.") {
                // This indicates the repository is private and requires authentication
                setShowCredentialsModal(true); // Show modal to input SSH key
                // No need to set error message or error type here since we are handling it with a modal
            } else {
                // Handle other types of errors
                setErrorMessage(message);
                setErrorType(errorType);
                setIsValidUrl(false);
            }
        } else {
            // General error handling for when the response does not contain detailed info
            console.error('Error:', error);
            setErrorMessage('An error occurred while processing the URL.');
            setErrorType('unknown');
            setIsValidUrl(false);
        }
    } finally {
        setShouldConnectWebSocket(false); // Close Websocket connection in the finally block
        setIsLoading(false); // Ensure loading is also reset in a finally block
    }
    };

    useEffect(() => {
        if (auditStarted && !showCredentialsModal) {
            const timer = setTimeout(() => {
                setShowMessage(true);
            }, 10000);  // Delay of 10 seconds

            return () => clearTimeout(timer);  // Cleanup the timer
        }
    }, [auditStarted, showCredentialsModal]);
    
    // Function to get domain logo
    const getDomainLogo = (repo_url) => {

        const domain = new URL(repo_url).hostname;
        const domainLogos = {
        "github.com": '/images/GitHub.png',
        "gitlab.com": '/images/GitLab.png',
        "bitbucket.org": "/images/Bitbucket.png",
        "SourceForge.net": "/images/SourceForge.png",
        "code.google.com": "/images/GoogleCode.png",
        "codeplex.com": "/images/CodePlex.png",
        "launchpad.net": "/images/Launchpad.png",
        "savannah.gnu.org": "/images/Savannah.png",
        "freecode.com": "/images/Freecode.png",
        "gitkraken.com": "/images/GitKraken.png",
        "beanstalkapp.com": "/images/Beanstalk.png",
        "assembla.com": "/images/Assembla.png",
        "phabricator.com": "/images/Phabricator.png",
        "gogs.io": "/images/Gogs.png",
        "gitea.com": "/images/Gitea.png",
        "gitbucket.com": "/images/GitBucket.png",
        "codeberg.org": "/images/Codeberg.png",
            // ... other domains if necessary
        };
        
        return domainLogos[domain] || '/images/default-logo.png'; // Path to a default logo
    };
 
    const getElapsedTime = (utcTimeString) => {
        const utcDate = new Date(utcTimeString);
        const userTime = utcToZonedTime(utcDate, userTimeZone);
        
        const currentTime = new Date();
        const diffYears = differenceInYears(currentTime, userTime);
        const diffMonths = differenceInMonths(currentTime, userTime) % 12;
        const diffWeeks = differenceInWeeks(currentTime, userTime);
        const diffDays = differenceInDays(currentTime, userTime) % 7;
        const diffHours = differenceInHours(currentTime, userTime) % 24;
        const diffMinutes = differenceInMinutes(currentTime, userTime) % 60;
        
        if (diffYears > 0) return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
        if (diffMonths > 0) return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
        if (diffWeeks > 0) return `${diffWeeks} week${diffWeeks > 1 ? 's' : ''} ago`;
        if (diffDays > 0) return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
        if (diffHours > 0) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
        if (diffMinutes > 0) return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
        return 'Just now';
    };
    
    const getRemainingTimeParts = (utcTimeString) => {
        const utcDate = new Date(utcTimeString);
        const endTime = new Date(utcDate.getTime() + 14 * 24 * 60 * 60 * 1000); // Adding 14 days for the end time
        const userEndTime = utcToZonedTime(endTime, userTimeZone); // Convert end time to user's timezone
        
        const currentTime = new Date();
        const diff = userEndTime - currentTime; // Compare against user's current time
    
        if (diff <= 0) {
            return { timeLeft: "Session expired", suffix: "" };
        }
    
        // Calculate remaining time components in user's timezone
        const diffDays = differenceInDays(userEndTime, currentTime);
        const diffHours = differenceInHours(userEndTime, currentTime) % 24;
    
        let timeLeft;
        if (diffDays > 0) {
            timeLeft = `${diffDays} day${diffDays > 1 ? 's' : ''}`;
            if (diffHours > 0) timeLeft += ` & ${diffHours} hour${diffHours > 1 ? 's' : ''}`;
        } else {
            timeLeft = `${diffHours} hour${diffHours > 1 ? 's' : ''}`;
        }
    
        return { timeLeft, suffix: "left before audit session expires" };
    };


  const repoCards = (audit) => {
    const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
    const isTargetStatus = targetStatuses.includes(audit.audit_status);
    const auditUrl = isTargetStatus ? `/${audit.audit_uuid}/audit-scope-selection` : `/${audit.audit_uuid}/audit_summary`;
    const { timeLeft, suffix } = getRemainingTimeParts(audit.ai_synthesis);

    return (
        <Link to={auditUrl} className="audit-item-link">
            <div className="audit-item">
                <img src={getDomainLogo(audit.repo_url)} className="repo-logo" alt="Repo Logo" />
                <div className="audit-details">
                    <span className={`repo-name ${isTargetStatus ? 'highlighted' : ''}`}>{(audit.audit_name)}
                    </span>
                    <span className="repo-url">{audit.repo_url}</span>
                </div>
                <div className="audit-info">
                        <span className="ai-synthesis">{getElapsedTime(audit.ai_synthesis)}</span>
                        <span className={`file-count ${isTargetStatus ? 'highlighted' : ''}`}>
                            {isTargetStatus ? "Select files to audit" : `${audit.file_count} files audited`}
                        </span>
                        <span className="audit-status">
                            {isTargetStatus ? (
                                <>
                                    <strong style={{fontSize: "16px"}}>{timeLeft}</strong> {suffix}
                                </>
                            ) : audit.audit_status}
                        </span>
                    </div>
            </div>
        </Link>
    );
};

const groupAuditCard = (groupAudit) => {
    const targetStatuses = ["Opening", "Group audit ready"];
    const isTargetStatus = targetStatuses.includes(groupAudit.audit_status);
    const groupAuditUrl = `/${groupAudit.audit_uuid}/audit-invitation`;
    const { timeLeft, suffix } = getRemainingTimeParts(groupAudit.ai_synthesis);

    // Updated icon logic
    let iconClass = "pi ";
    let iconStyleClass = "";
    switch (groupAudit.audit_status) {
        case "Opening":
            iconClass += "pi-power-off";
            iconStyleClass = "highlighted";
            break;
        case "Group audit ready":
            iconClass += "pi-check-circle";
            iconStyleClass = "ready";
            break;
        default:
            iconClass += "pi-circle";
            iconStyleClass = "";
    }

    // Updated class names for highlighting
    let nameClassName = "repo-name";
    let statusClassName = "audit-status";
    if (groupAudit.audit_status === "Opening") {
        nameClassName += " highlighted";
        statusClassName += " highlighted";
    } else if (groupAudit.audit_status === "Group audit ready") {
        nameClassName += " ready";
        statusClassName += " ready";
    }

    return (
        <Link to={groupAuditUrl} className="audit-item-link">
            <div className="audit-item">
                <i className={`${iconClass} ${iconStyleClass} repo-logo`} style={{fontSize: '30px', marginLeft: '3px'}}></i>
                <div className="audit-details">
                    <span className={nameClassName}>
                        {groupAudit.audit_name}
                    </span>
                    <span className="repo-info">
                        {groupAudit.number_of_related_audits} repositories in this group
                    </span>
                </div>
                <div className="audit-info">
                    <span className="ai-synthesis">
                        {getElapsedTime(groupAudit.ai_synthesis)}
                    </span>
                    <span className={`file-count ${isTargetStatus ? statusClassName : ''}`}>
                        {groupAudit.audit_status === "Group audit ready" 
                            ? "Audit ready" 
                            : (isTargetStatus ? "Audit list not complete" : `${groupAudit.number_files} files and ${groupAudit.lines_of_code} lines of code audited`)}
                    </span>
                    <span className={statusClassName}>
                        {isTargetStatus ? (
                            <>
                                <strong style={{fontSize: "16px"}}>{timeLeft}</strong> {suffix}
                            </>
                        ) : groupAudit.audit_status}
                    </span>
                </div>
            </div>
        </Link>
    );
};

const getSortedAudits = useCallback(() => {
    const combinedAudits = [...audits, ...groupAudits.map(audit => ({...audit, isGroupAudit: true}))];
    combinedAudits.sort((a, b) => new Date(b.ai_synthesis) - new Date(a.ai_synthesis));
    return combinedAudits;
}, [audits, groupAudits]);

const canLoadMore = totalItems > audits.length + groupAudits.length;

    const handleLoadMore = () => {
        if (canLoadMore) {
            fetchAudits(true);
        }
    };

return (
    <div className="user-dashboard-container">
        <InviteToAudit accountUuid={accountUuid}/>

        <EnterRepoCredentials
            isVisible={showCredentialsModal}
            onHide={() => setShowCredentialsModal(false)}
            gitUrl={gitUrl}
            style={{ width: '900px' }}
            onAuthenticate={handleSubmit}
            isLoading={isLoading}
            accountUuid={accountUuid}
        />

        <div className="divider-container">
            <hr className="line line-left" />
            <span className="divider-text">or</span>
            <hr className="line line-right" />
        </div>

        <div className="audit-repo-title">Audit a git repository directly</div>
        
        <div className="audit-repo-inputcontainer-dashboard">
            <span className="audit-repo-inputfield-dashboard">
                {isCheckingUrl && <i className="pi pi-spin pi-spinner" />}
                <InputText
                    id="gitUrl"
                    value={gitUrl}
                    style={{ width: '550px' }}
                    onChange={handleInputChange}
                    placeholder="e.g. https://github.com/tensorflow/tensorflow"
                    className={`${isValidUrl ? '' : 'p-invalid'}`}
                />
            </span>
            <Button label="Analyze" onClick={handleSubmit} disabled={isLoading || isCheckingUrl} />
            {isLoading && <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" />}
            <div className="error-message-container">
                {errorType && <Message severity="error" text={errorMessage} />}
            </div>
        </div>

        {auditStarted && !showCredentialsModal && showMessage && (
            <div className='audit-status-container'>
                <div className="audit-status-message" style={{color: '#8D8D8D', fontSize: '16px', marginTop: '5px'}}>Your audit started. This might take a few minutes depending on the size of the repo...</div>
            </div>
        )}

            {(audits.length > 0 || groupAudits.length > 0) && (
                <div className="repository-list-section">
                    <DataScroller 
                        value={getSortedAudits()} 
                        itemTemplate={(audit) => audit.isGroupAudit ? groupAuditCard(audit) : repoCards(audit)}
                        rows={limit}
                        loader={isLoading}
                        footer={ canLoadMore || (hasLoadedMore && !canLoadMore) ? ( 
                            <Button 
                                label={canLoadMore ? "Load More" : "No More Items"}
                                onClick={handleLoadMore} 
                                disabled={isLoading || !canLoadMore} 
                            />
                        ) : null
                        }
                    />
                </div>
            )}
            {isLoading && <ProgressSpinner style={{marginTop: '3rem', with: '50px', height: '50px'}}/>}
    </div>
);
}

export default UserDashboard;