import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Registration.css';

const Registration = () => {
    const { email: encodedEmailParam } = useParams();
    const emailParam = encodedEmailParam ? decodeURIComponent(encodedEmailParam) : '';
    const [email, setEmail] = useState(emailParam || '');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputComplete, setInputComplete] = useState(false);
    const emailRef = useRef(null);
    const fullNameRef = useRef(null);
    const passwordRef = useRef(null);
    const navigate = useNavigate(); 

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email === '' || regex.test(email);
    };

    const validateFullName = (name) => {
        const regex = /^[a-zA-Z\s]*$/;
        return regex.test(name);
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[!@#$%^&*])/;
        return password === '' || (password.length >= 8 && regex.test(password));
    };

    const validateForm = () => {
        return email !== '' && fullName !== '' && password !== '' && 
               validateEmail(email) && validateFullName(fullName) && validatePassword(password) &&
               Object.keys(errors).length === 0;
    };

    useEffect(() => {
        setInputComplete(validateForm());
    }, [email, fullName, password, errors]);

    useEffect(() => {
        if (emailParam) {
            handleInputChange('email', emailParam);
        }
    }, [emailParam]);

    const handleInputChange = (field, value) => {
        let newErrors = { ...errors };
        delete newErrors[field];

        switch (field) {
            case 'email':
                setEmail(value);
                if (!validateEmail(value) && value !== '') newErrors.email = 'Invalid email address';
                break;
            case 'fullName':
                setFullName(value);
                if (!validateFullName(value)) newErrors.fullName = 'Full name should contain only letters and spaces';
                break;
            case 'password':
                setPassword(value);
                if (!validatePassword(value) && value !== '') newErrors.password = 'Password must be at least 8 characters long and contain at least one special character';
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setIsLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/register/`, {
                    email,
                    full_name: fullName,
                    password
                });

                console.log('Registration successful', response.data);
                setIsLoading(false);

                if (response.data.complete_registration_url) {
                    navigate(response.data.complete_registration_url);
                }
            } catch (error) {
                setIsLoading(false);
                if (error.response && error.response.data) {
                    setErrors({ api: error.response.data.message });
                } else {
                    setErrors({ api: 'An unexpected error occurred' });
                }
            }
        }
    };

    const handleKeyDown = (e, field) => {
        if (e.key === 'Enter') {
            switch (field) {
                case 'email':
                    fullNameRef.current.focus();
                    break;
                case 'fullName':
                    passwordRef.current.focus();
                    break;
                case 'password':
                    if (inputComplete) {
                        handleSubmit();
                    }
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div className="registration-container">
            <Card className="registration-card">
                <div className="registration-header">
                    <h1>Start your free CodeDD trial</h1>
                    <h3>Your CodeDD trial will grant you 10,000 Lines of Code (LoC) to review any public or private code repositories.</h3>
                </div>
                <div className="p-fluid">
                    <div className="p-field">
                        <span className="p-float-label">
                        <InputText
                            id="email"
                            value={email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, 'email')}
                            ref={emailRef}
                            className={errors.email ? 'p-invalid' : ''}
                            placeholder=" "
                        />
                            <label htmlFor="email">Email</label>
                        </span>
                        {errors.email && <small className="p-error">{errors.email}</small>}
                    </div>
                    <div className="p-field">
                        <span className="p-float-label">
                        <InputText
                            id="fullName"
                            value={fullName}
                            onChange={(e) => handleInputChange('fullName', e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, 'fullName')}
                            ref={fullNameRef}
                            className={errors.fullName ? 'p-invalid' : ''}
                            placeholder=" "
                        />
                            <label htmlFor="fullName">Full Name</label>
                        </span>
                        {errors.fullName && <small className="p-error">{errors.fullName}</small>}
                    </div>
                    <div className="p-field">
                    <span className="p-float-label">
                            <Password
                                id="password"
                                value={password}
                                onChange={(e) => handleInputChange('password', e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e, 'password')}
                                ref={passwordRef}
                                className={errors.password ? 'p-invalid' : ''}
                                feedback={false}
                                inputprops={{ tabIndex: 0 }}
                                panelProps={{ tabIndex: -1 }}
                                keyfilter={/^[^\s]*$/}  // Prevents spaces in password
                            />
                            <label htmlFor="password">Password</label>
                        </span>
                        {errors.password && <small className="p-error">{errors.password}</small>}
                    </div>
                    <Button
                        label={isLoading ? "Starting trial..." : "Start free trial"}
                        className="start-audit-button"
                        onClick={handleSubmit}
                        disabled={isLoading || !inputComplete}
                    />
                    {errors.api && <small className="p-error">{errors.api}</small>}
                    <p className="terms-text">
                        By clicking "Start free CodeDD trial" you accept the <a href="/terms">CodeDD Terms of Use</a> and acknowledge the <a href="/privacy-statement">Privacy Statement</a> and <a href="/data-processing-addendum">Data Processing Addendum</a>.
                    </p>
                </div>
            </Card>
        </div>
    );
};

export default Registration;