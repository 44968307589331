import React, { useState } from 'react';
import { Button } from 'primereact/button';
import './Account.css';

const Account = () => {
    const [activeMenu, setActiveMenu] = useState('profile');

    const menuItems = [
        { label: 'Profile', value: 'profile', icon: 'pi pi-user' },
        { label: 'Billing', value: 'billing', icon: 'pi pi-credit-card' },
        { label: 'Account', value: 'account', icon: 'pi pi-cog' },
    ];

    const renderContent = () => {
        switch (activeMenu) {
            case 'profile':
                return <div>Profile Content</div>;
            case 'billing':
                return <div>Billing Content</div>;
            case 'account':
                return <div>Account Content</div>;
            default:
                return null;
        }
    };

    return (
        <div className="account-container">
            <div className="account-menu">
                {menuItems.map((item) => (
                    <Button
                        key={item.value}
                        label={item.label}
                        icon={item.icon}
                        className={`p-button-text ${activeMenu === item.value ? 'p-button-primary' : ''}`}
                        onClick={() => setActiveMenu(item.value)}
                    />
                ))}
            </div>
            <div className="account-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default Account;