import axios from 'axios';

// Action Type
export const SET_AUDIT_STATUS = 'SET_AUDIT_STATUS';
export const SET_AUDIT_ERROR = 'SET_AUDIT_ERROR';

// Action Creator for checking if an audit UUID is public
export const checkAuditStatus = (audit_uuid) => async (dispatch, getState) => {
  const { user } = getState(); // Access the current state
  const account_uuid = user.accountUuid; // Get accountUuid from the user slice

  if (!audit_uuid) return;

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/is_uuid_path_public/`, {
      params: { audit_uuid, account_uuid }
    });
    console.log('Dispatching SET_AUDIT_STATUS:', { 
      uuid: audit_uuid, 
      isPublic: response.data.publicity === 'public',
      auditStatus: response.data.auditStatus,
      hasAccess: response.data.hasAccess,
      isManager: response.data.isManager
    });
    // Dispatch based on public status and access rights
    dispatch({
      type: SET_AUDIT_STATUS,
      payload: { 
        uuid: audit_uuid, 
        isPublic: response.data.publicity === 'public',
        auditStatus: response.data.auditStatus,
        hasAccess: response.data.hasAccess,
        isManager: response.data.isManager
      }

    });

    // If the audit is private and the user does not have access, handle it as an error
    if (!response.data.hasAccess && response.data.publicity === 'private') {
      dispatch({
        type: SET_AUDIT_ERROR,
        payload: { 
          uuid: audit_uuid, 
          error: 'Access Denied'
        }
      });
    }
  } catch (error) {
    console.error('Failed to fetch audit status:', error);
    dispatch({
      type: SET_AUDIT_ERROR,
      payload: { 
        uuid: audit_uuid, 
        error: 'Failed to connect to API'
      }
    });
  }
};
