import React from 'react';
import './Header.css';

const Header = ({ auditName, fileCount, repoUrl, repoDomain, domainLogos }) => {
  const logoUrl = repoDomain && domainLogos && (repoDomain in domainLogos) ? domainLogos[repoDomain] : null;

  return (
    <div className="header">
      <a href={repoUrl} target="_blank" rel="noopener noreferrer">
        {logoUrl && <img src={process.env.PUBLIC_URL + logoUrl} alt={repoDomain} />}
        <div className="header-text">
          <div className={auditName ? "audit-name" : "auditing_loader"}>
            {auditName || ''}
          </div>
          <div className="repository-url">{repoUrl}</div>
          <div className="file-count">{fileCount} files audited</div>
        </div>
      </a>
    </div>
  );
};

export default Header;